import React, { createContext, useContext, useState } from "react";

const AppContext = createContext({});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {

    const [activeTab, setActiveTab] = useState("tab1");
    const [userPlayload, setUserPlayload] = useState({});
    const [userSubscription, setUserSubscription] = useState({});
    const [userInvoice, setUserInvoice] = useState({});
    const [tabs, setTabs] = useState("tab");
    const [newUserCreated, setNewUserCreated] = useState(false);

    const contextValue = {
        activeTab,
        setActiveTab,
        userPlayload,
        setUserPlayload,
        userSubscription,
        setUserSubscription,
        userInvoice,
        setUserInvoice,
        tabs,
        setTabs,
        newUserCreated,
        setNewUserCreated
    };
        
    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
