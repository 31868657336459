import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store';
import { MantineProvider, createTheme } from '@mantine/core';
import {AppProvider} from './AppConext';

import '@mantine/core/styles.css';

//import 'crypto-browserify';
//import 'stream-browserify';

// ...rest of your imports


import './index.css';

const theme = createTheme({
  /** Put your mantine theme override here */
});

ReactDOM.render(
  <Provider store={store}>
      <MantineProvider theme={theme}>
        <AppProvider>
          <App />
        </AppProvider>
      </MantineProvider>
    
  </Provider>,
  document.getElementById('root')
);
