import React, { useState } from 'react'
import DateRangePickerCalendarExample from '../hooks/Others/DateRangePicker'
import Calender from "../assests/Calendar.png";

interface Props {
    getDateValue:(arg:{
        startDate?: string  | null | any,
        endDate?: string  | null | any
    }) => void;
    dates?:{startDate:null | string, endDate:null | string }

}

const DateComponent:React.FC<Props> = (props) => {
    const {getDateValue, dates} = props
    const [showDate, setshowDate] = useState(false)
    const [dateType, setdateType] = useState('')

    const onPickDateType = (type: any) => {
        setdateType(type)
        setshowDate(true)
    }

    const handleDateChange = (value: any) => {
        getDateValue({
            startDate: value.startDate,
            endDate: value.endDate,
        })
      };

    return (
        
        <div>
    
            <span className="flex relative    w-full h-full ">
                <button onClick={() => onPickDateType('range')} className="flex">
                    Date/Time
                    <img className='ml-2' src={Calender} alt="" />
                </button>
            </span>
    
            {showDate && (
                <div className="">
                    <div
                        className="fixed  bg-black opacity-25 inset-0 h-[130vh] z-20"
                    ></div>
    
                    <div
                        onClick={() => setshowDate(false)}
                        className="fixed inset-0 backdrop-blur-sm h-[130vh] z-30"
                    ></div>
                    
                    <DateRangePickerCalendarExample
                        type={dateType}
                        dates={dates}
                        onClose={() => setshowDate(false)}
                        onClickDone={() => setshowDate(false)}
                        onChange={handleDateChange}
                    />
                </div>
            )}
    
        </div>
    )
}
export default DateComponent