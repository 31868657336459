import React, { useState, useEffect } from "react";
import { TfiClose } from "react-icons/tfi";
import Loader from "./resources/Loader";
import ErrorMessage from "./resources/ErrorMessage";
import SearchBar from "./resources/SearchBar";
import SearchGreen from "../../components/svg-icons/SearchGreen";
import WhitePlus from "../../components/svg-icons/WhitePlus";
import Tabrow from "../../utils/Tabrow";
import TabrowAddAccount from "../../utils/TabrowAddAccount";
import Calender from "../../assests/Calendar.png";
import useInFor from "../../hooks/APIrequest/UseInFor";
import convertDateTime from "./resources/DateConverter";
import useAccountPagination from "../../hooks/Paginations/useAccountPagination";
import Arrow from "../../components/svg-icons/Arrow";
import useAccApi from "../../hooks/APIrequest/useAccApi";
import DateRangePickerCalendarExample from "../../hooks/Others/DateRangePicker";
import { useAppContext } from "../../AppConext";

//no record icon
import noRecords from "../../assests/noRecords.json";
import Lottie from "lottie-react";

//redux
import { useSelector, useDispatch } from "react-redux";
import { selectActiveTabLabel, setActiveTabLabel } from "../../store/tab-slice";

//hook for seting tabs to default paperLink
import useCustomActiveTabs from "../../hooks/Others/useCustomActiveTabs";
import Empty from "./resources/Empty";
import DateComponent from "../../components/DateComponent";
import PaginationComponent from "../../components/paginationComponent";

const {REACT_APP_BASE_URL} = process.env;

const makeStyle = (status: string) => {
  let color = "";
  if (status === "New Trial") {
    color = "rgb(173, 82, 245)";
  } else if (status === "active") {
    color = "rgb(95, 163, 72)";
  } else if (status === "Pause") {
    color = "rgb(235, 185, 5)";
    color = "rgb(235, 185, 5)";
  } else if (status === "Cancel") {
    color = "rgb(20, 0, 255)";
  } else if (status === "Delete") {
    color = "red";
  }
  return {
    color,
  };
};

interface AppContext {
  tabs: string;
  setTabs: (payload: string) => void;
  newUserCreated: boolean;
  setNewUserCreated: (payload: boolean) => void;
}

const Accounts = () => {
  const { tabs, setTabs, newUserCreated } = useAppContext() as AppContext;
  const [inputClick, setInputClick] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [records, setRecords] = useState(false);
  const [modalOne, setModalOne] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(""); // Initialize with a default filter value
  const [filterAll, setFilterAll] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);
  const [timeFilter, setTimeFilter] = useState<any>([null, null]);
  const [selecteduser, setselecteduser] = useState(null);
  const [startDate, setstartDate] = useState<null | string>(null);
  const [endDate, setendDate] = useState<null | string>(null);
  const [pageNumber, setpageNumber] = useState<number>(1);

  //To set default on paperlink and also to set the active tob for the switch to define endponit
  const { customActiveTab } = useCustomActiveTabs();
  const activeTab = useSelector(selectActiveTabLabel);
  const dispatch = useDispatch();
  dispatch(setActiveTabLabel(customActiveTab));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    // setFilterAll(false);
  };

  //fetching data
  const { loading, data, users, error, setIsDatePicked, isDatePicked } =
    useAccApi(
      searchValue,
      selectedFilter,
      startDate,
      endDate,
      pageNumber * 10 - 10,
      newUserCreated
    );
    
  //paginated data
  const {
    currentPost,
    paginationButtons,
    nextButton,
    prevButton,
    viewAllButton,
  } = useAccountPagination(
    1,
    searchValue,
    selectedFilter,
    users,
    filterAll,
    isDatePicked!
  );

  const handleSearch = (value: string) => {
    const cleanedValue = value.replace(/\s+/g, "_").trim();
    setSearchValue(value);
    setstartDate(null);
    setendDate(null);
    setSearchValue(cleanedValue);
  };
  const handleInputClick = () => {
    setInputClick(!inputClick);
    setSearchValue("");
    setFilterAll(false);
  };

  const recordFound = currentPost.length > 0;

  useEffect(() => {
    if (currentPost.length === 0 && inputClick) {
      setRecords(true);
    } else {
      setRecords(false);
    }
  }, [currentPost, inputClick]);

  //tabs redirect
  const handleTabs = (user: any) => {
    setselecteduser(user);
    setTabs("userDetails");
  };

  const handleAddAccount = () => {
    setTabs("addAccount");
  }

  //handle modal of the date close
  const handleCloseSelectedDate = () => {
    setSearchValue("");
    setSelectedFilter("")
    if (inputClick) {
      setInputClick(!inputClick);
    }
    setSelectedDate(false);
  };
  // handling the date picker
  const getDateValuesFunc = (arg: {
    startDate?: string | null;
    dayDate?: string | null;
    endDate?: string | null;
  }) => {
    const { startDate, dayDate, endDate } = arg;
    if (dayDate) {
      setendDate(dayDate);
      setstartDate(dayDate);
    } else {
      setendDate(endDate ?? null);
      setstartDate(startDate ?? null);
    }
    startDate && endDate && handleCloseSelectedDate();
    setIsDatePicked(true);
  };

  // Green plus sign modal
  const handleModalOne = () => {
    setModalOne(!modalOne);
  };

  const handleModalClose = () => {
    setModalOne(false);
  };
  //for status
  const handleStatusFilter = (e: any) => {
    const selectedValue = e.target.getAttribute("data-value"); // Get the data-value attribute
    setSelectedFilter(selectedValue); // Update the selected filter state
    setSearchValue(selectedValue);
    setstartDate(null);
    setendDate(null);
    if (inputClick) {
      setInputClick(!inputClick);
    }
    
    //to see evry data concerning that field you use filter all which will reomve pagination
    setFilterAll(true);
    toggleDropdown();
  };

  return (
    <div>
    {isOpen && (
      <div className="relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
      <div className="absolute top-[8rem] right-[2.0rem] inline-block outline-none m-auto ease-in-out duration-1000 h-auto z-10 w-full cursor-pointer bg-gray-200 shadow-lg"
      style={{ zIndex: 1000,width: '6.25rem', height: '22.75rem' }}>
        <div
          onClick={handleStatusFilter}
          data-value="" // Assign a data attribute to store the value
          className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
        >
          All
        </div>
        <div
          onClick={handleStatusFilter}
          data-value="Active" // Assign a data attribute to store the value
          className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
        >
          Active
        </div>
        <div
          onClick={handleStatusFilter}
          data-value="Cancel" // Assign a data attribute to store the value
          className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
        >
          Cancel
        </div>
        <div
          onClick={handleStatusFilter}
          data-value="Disabled" // Assign a data attribute to store the value
          className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
        >
          Disabled
        </div>
        <div
          onClick={handleStatusFilter}
          data-value="new_trial" // Assign a data attribute to store the value
          className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
        >
          New Trial
        </div>
        <div
          onClick={handleStatusFilter}
          data-value="Pause" // Assign a data attribute to store the value
          className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
        >
          Pause
        </div>
        <div
          onClick={handleStatusFilter}
          data-value="Suspend" // Assign a data attribute to store the value
          className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
        >
          Suspend
        </div>

        {/* <div
          className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={}
        >
          <TfiClose />
        </div> */}
      </div>
      </div>
    )}
    <div className=" mb-[150px] border-radius-[0.9375rem] bg-white width-[65.75rem] h-auto overflow-hidden font-Poppins rounded-lg">
      {tabs === "tab" ? (
        <div>
          <div className="bg-secondaryColor flex items-center justify-between px-6 md:py-2 rounded-t-lg">
            <div className="border-b-0 text-[#707070] !font-[500] leading-normal text-lg md:text-[1.2rem]">
              Accounts
            </div>
            <div className="border-b-0 flex items-center w-full justify-end ">
              <span className="  inline-flex items-center justify-center h-12 flex-shrink-0 fill-current rounded-full shadow-drop mr-6 ">
                <div className="border-b-0 flex gap-3">
                  {!inputClick ? (
                    ""
                  ) : (
                    <div className=" bottom-0 border-1 border-green-300 ">
                      <SearchBar
                        onSearch={(value) => handleSearch(value)}
                        inputClick={inputClick}
                        placeholder="Search users..."
                      />
                    </div>
                  )}
                  {!inputClick ? (
                    <button
                      type="button"
                      onClick={handleInputClick}
                      className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current bg-white rounded-full shadow-drop outline-none hover:opacity-90"
                    >
                      <SearchGreen />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleInputClick}
                      className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current bg-slate-700 transition-all ease-in-out duration-700 hover:bg-red-500 rounded-full shadow-drop outline-none"
                    >
                      <TfiClose color="white" />
                    </button>
                  )}
                </div>
              </span>
              <div className="border-b-0 h-full items-center flex">
                <span
                  onClick={handleAddAccount}
                  className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current active:bg-slate-300 bg-[#75C05F] cursor-pointer hover:opacity-90 rounded-full shadow-drop"
                >
                  <WhitePlus />
                </span>
              </div>
              
            </div>
          </div>

          {activeTab === "Paperlink" ? (
            <>
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <>
                  <div className="md:hidden ">
                    {/* if there are records display this */}
                    {recordFound ? (
                      <div className="flex flex-col w-full p-3 justify-center align-middle text-sm ">
                        {currentPost.map((user) => (
                          <div
                            key={user.id}
                            className="shadow-xl my-5 rounded-xl w-full hover:bg-slate-200 "
                          >
                            <div className="w-full mt-3 text-center mb-2 rounded-t-xl  border-2 border-gray-300 p-3">
                              <span
                                onClick={() => handleTabs(user)}
                                className="text-blue-700 underline ml-3"
                              >
                                {user.email}
                              </span>
                            </div>
                            <div className=" text-sm justify-center text-center flex">
                              Business name: {user.companyName}
                            </div>
                            <div
                              className="text-center"
                              style={makeStyle(user.status)}
                            >
                              Status: {user.status}
                            </div>
                            <div className=" w-full flex justify-between text-green-800 bg-green-300 p-2 rounded-b-xl">
                              Date/time: {convertDateTime(user.createdAt)}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {/* If there are no records display this */}
                        {error && <ErrorMessage message={error} />}
                      </div>
                    )}
                    {!records ? (
                      ""
                    ) : (
                      <div className="text-center py-4 w-full bg-green-300 text-2xl text-green-700">
                        Search complete. No record found
                      </div>
                    )}
                  </div>
                  {/* Desktop view */}
                  <div className="hidden md:inline w-full">
                      <table className="w-full table-hover user-table">
                        <thead className="p-5">
                          <tr className="">
                            <th className=" p-5 px-8 text-left font-bold text-darkGray text-sm">
                              <DateComponent getDateValue={getDateValuesFunc} />
                            </th>
                            <th className="border-b px-4 py-3 font-bold text-left text-darkGray text-sm">
                              Account Email
                            </th>
                            <th className="border-b  py-3 font-bold text-left text-darkGray text-sm">
                              Business Name
                            </th>
                            <th className="border-b  py-3 font-bold text-left text-darkGray text-sm">
                              Paperlink#
                            </th>
                            <th className="p-2 py-3 relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
                              <span className="">
                                <div
                                  className="flex items-center cursor-pointer"
                                  onClick={toggleDropdown}
                                >
                                  <p>Status</p>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#222529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M6 4v4"></path><path d="M6 12v8"></path><path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M12 4v10"></path><path d="M12 18v2"></path><path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M18 4v1"></path><path d="M18 9v11"></path></svg>
                                </div>
                              </span>
                              
                            </th>
                          </tr>
                        </thead>
                        {recordFound ? (
                        <tbody className="cursor-pointer">
                          {currentPost.map((userDetail) => (
                            <tr
                              key={userDetail.id}
                              className=" border-gray-200 hover:bg-gray-100"
                            >
                              <td className=" border-t py-3 px-3 text-left font-Poppins text-lightGray">
                                <div className="flex flex-col">
                                  <p className="text-lightGray font-Poppins font-normal leading-normal px-3 text-sm">
                                    {/* Date Created */}
                                    {convertDateTime(userDetail.createdAt)}
                                  </p>
                                </div>
                              </td>

                              <td
                                onClick={() => handleTabs(userDetail)}
                                className=" border-t py-4 hover:text-red-500 underline  text-blue-500 font-Poppins text-sm font-normal px-3 text-left"
                              >
                                {/* User Email */}
                                {userDetail.email}
                              </td>
                              <td className=" border-t py-4 px-2 text-left text-lightGray font-Poppins text-sm font-normal">
                                {/* Company Email */}
                                {userDetail.companyName}
                              </td>

                              
                              <td className="border-t py-4 px-2 text-left font-Poppins text-sm font-normal"
                              style={{ color: '#75C05F' }}

                              onClick={userDetail.isEmailVerified === 1 ? 
                                () => window?.open(`${REACT_APP_BASE_URL}/${userDetail.businessPage}`, "_blank") : 
                                undefined}>
                                
                                {userDetail.isEmailVerified === 1? (
                                  <div>
                                    {/* Company Website */}
                                    /{userDetail.companyName.replace(/\s+/g, '')}
                                  </div>
                                ) : (
                                  <div>
                                    {/* Email Verified Label */}
                                    <div className="text-lightGray">
                                      Not Verified
                                    </div>
                                  </div>
                                )}
                              </td>

                              {/* userDetail.isEmailVerified */}

                              <td className="border-t py-4 px-3  text-left text-lightGray font-Poppins text-sm font-normal ">
                                {/* Status */}
                                <span
                                  className="status"
                                  style={makeStyle(userDetail.status)}
                                >
                                  {userDetail.status.replace(/_/g, " ").replace(/^./, (match: string) => match.toUpperCase())}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        ) : (
                          <div>
                            {/* If there are no records display this */}
                            {error && <ErrorMessage message={error} />}
                          </div>
                        )}
                      </table>
                    
                    {!error && users.length === 0 && !loading && (
                      // when there are no records
                      <Empty activeTab={activeTab} searchValue={searchValue} />
                    )}
                  </div>
                </>
              )}
              {data.total > 10 && (
                <PaginationComponent
                  total={data.total}
                  limit={10}
                  onChange={setpageNumber}
                  activePage={pageNumber}
                />
              )}
            </>
          ) : (
            <div className="h-[50vh] w-full mx-auto flex text-center font-extralight mt-3 flex-col">
              <h2>Sorry, No Records Found in {activeTab}</h2>
              <div className="h-[100%] w-full flex justify-center ">
                {/* <Lottie loop={false} animationData={noRecords} /> */}
              </div>
            </div>
          )}
        </div>
      ) :
      tabs === "userDetails" ? (
        <div>
          <Tabrow user={selecteduser} />
        </div>
      )
      : (
        <div>
          <TabrowAddAccount user={selecteduser} />
        </div>
      )
    }
    </div>
    </div>
  );
};

export default Accounts;
