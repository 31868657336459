import React, { useState, useEffect, useRef } from "react";
import Dots from "../../components/svg-icons/Dots";
import DeleteIcon from "../../components/svg-icons/DeleteIcon";
import EditIcon from "../../components/svg-icons/EditIcon";
import axiosInstance from "../../utils/axiosInstance";
import { PaperLinkFAQ, FAQ } from "./resources/PaperLinkFAQ";
import FirstModal from "../../components/FQAModals/FirstModal";
import SecondModal from "../../components/FQAModals/SecondModal";
import DeleteModal from "../../components/FQAModals/DeleteModal";
import EditFQAModal from "../../components/FQAModals/EditFQAModal";
import EditCategoryModal from "../../components/FQAModals/EditCategoryModal";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
//import { constants } from "http2";
import Loader from "./resources/Loader";

//redux
import { useSelector, useDispatch } from "react-redux";
import { selectActiveTabLabel, setActiveTabLabel } from "../../store/tab-slice";
import { ClipLoader } from "react-spinners";
import { ActionIcon, Box, Flex, Text } from "@mantine/core";


const Qna = () => {
  const [modalOne, setModalOne] = useState(false);
  const [modalTwo, setModalTwo] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [toDeteleId, setToDeleteId] = useState<any>(null);
  const [deleteType, setDeleteType] = useState<string>("");
  const [categoryvalueToDelete, setCategoryValueToDelete] = useState<string>("");

  const [paperLinkFAQs, setPaperLinkFAQs] = useState<PaperLinkFAQ[]>([]);
  const [showPaperLinks, setShowPaperLinks] = useState<boolean[]>([]);
  const [FAQs, setFAQs] = useState<FAQ[]>([]);
  const [showFQA, setShowFQA] = useState<boolean[]>([]);
  const [editFAQ, setEditFAQ] = useState<FAQ | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editCategory, setEditCategory] = useState<PaperLinkFAQ | null>(null);
  const [loading, setLoading] = useState(false); // Set it to true initially or use an appropriate initial value
  const [success, setSuccess] = useState(false);
  const [visibleSave, setVisibleSave] = useState(false);
  const [visibleLoaderSave, setVisibleLoaderSave] = useState(false);
  const [afterSave, setAfterSave] = useState(false);

  const activeTab = useSelector(selectActiveTabLabel);

  // State for dragged item for category
  const [draggedItem, setDraggedItem] = useState<FAQ | null>(null);
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);

  const dispatch = useDispatch();

  //DO NOT TOUCH
  let customActiveTab = "Paperlink";
  if (activeTab === "Signinlink") {
    customActiveTab = "Signinlink";
  } else if (activeTab === "Timelink") {
    customActiveTab = "Timelink";
  } else if (activeTab === "RXlink") {
    customActiveTab = "RXlink";
  } else if (activeTab === "Hostlink") {
    customActiveTab = "Hostlink";
  }
  dispatch(setActiveTabLabel(customActiveTab));

  //yeah you can start touching
  let endpoint = "paperlink";

  // Check your circumstances and modify the endpoint accordingly
  if (activeTab === "Signinlink") {
    endpoint = "signinlink";
  } else if (activeTab === "Timelink") {
    endpoint = "timelink";
  } else if (activeTab === "RXlink") {
    endpoint = "rxlink";
  } else if (activeTab === "Hostlink") {
    endpoint = "hostlink";
  }

  // Calling APIs
  useEffect(() => {
    // Fetch Junologix  FAQs
    setLoading(true);

    axiosInstance
      .get(`/categories?$sort[position]=1&for=` + endpoint)
      .then((response) => {
        setPaperLinkFAQs(response.data as PaperLinkFAQ[]);
        setShowPaperLinks(new Array(response.data.length).fill(false));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching PaperLink FAQs:", error);
        setLoading(false);
      });

    // Fetch FAQs
    axiosInstance
      .get(`/faq?$sort[position]=1&for=` + endpoint)
      .then((response) => {
        setFAQs(response.data as FAQ[]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching FAQs:", error);
        setLoading(false);
      });
  }, [activeTab]);

  // Modal functions

  // Toggle category
  const togglePaperLink = (index: number) => {
    const updatedShowPaperLinks = [...showPaperLinks];
    updatedShowPaperLinks[index] = !updatedShowPaperLinks[index];
    setShowPaperLinks(updatedShowPaperLinks);
  };

  // Toggle FAQ
  const toggleFQA = (index: number) => {
    const updatedShowFQA = [...showFQA];
    updatedShowFQA[index] = !updatedShowFQA[index];
    setShowFQA(updatedShowFQA);
  };

  // Handle modal close
  const handleModalClose = () => {
    setModalOne(false);
    setModalTwo(false);
  };

  // handle modal one
  const handleModalOne = () => {
    setModalOne(!modalOne);
  };

  // handle delete modal
  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  }

  // handle modal two
  const handleModalTwo = () => {
    setModalTwo(!modalTwo);
  };

  // Open edit modal
  const openEditModal = (faq: FAQ) => {
    setEditFAQ(faq);
    setIsEditing(true);
  };

  // Close edit modal
  const closeEditModal = () => {
    setEditFAQ(null);
    setIsEditing(false);
  };

  // Handle drag over for category item
  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
  };

  // Handle drop for category item
  const handleDrop = (index: number) => {
    if (draggedItem && draggedItemIndex !== null) {
      const updatedFAQs = [...FAQs];
      updatedFAQs.splice(draggedItemIndex, 1);

      const updatedCategory = paperLinkFAQs[index];
      updatedCategory.faqs.push(draggedItem);

      setFAQs(updatedFAQs);
      setDraggedItem(null);
      setDraggedItemIndex(null);
    }
  };

  // Handle drag end for categories
  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return; // Dropped outside the list

    const reorderedCategories = Array.from(paperLinkFAQs);
    const [removedCategory] = reorderedCategories.splice(
      result.source.index,
      1
    );
    reorderedCategories.splice(result.destination.index, 0, removedCategory);
    // let extractedreorderCategory = reorderedCategories.map((item, index) => {
    //   return {
    //     id: item.id,
    //     position: ++index,
    //   };
    // });
   
    // compare each item in the array of reorderedFAQs with the FAQs array to check if there is a change in position
    let isMatch = true;
    for (let i = 0; i < reorderedCategories.length; i++) {
      if (reorderedCategories[i].id !== paperLinkFAQs[i].id) {
        isMatch = false;
        break;
      }
    }

    if (!isMatch) {
      setVisibleSave(true);
      setPaperLinkFAQs(reorderedCategories);
    } else {
      setVisibleSave(false);
    }
    // Send a POST request to update the server with the new category order
    // try {
    //   await axiosInstance.post(
    //     `/categories?$sort[position]=1&for=` + endpoint,
    //     {
    //       action: "orderCategories",
    //       order: extractedreorderCategory,
    //     }
    //   );
    // } catch (error) {
    //   console.error("Error updating categories:", error);
    // }
  };

  const handleSave = async () => {
    setVisibleLoaderSave(true);
    let extractedreorderCategory = paperLinkFAQs.map((item, index) => {
      return {
        id: item.id,
        position: ++index,
      };
    });

    try {
      let res = 0

      await axiosInstance.post(
        `/categories?$sort[position]=1&for=` + endpoint,
        {
          action: "orderCategories",
          order: extractedreorderCategory,
        }
      ).then((response) => {
        if(response.data === "updated successfully"){
          res += 1
        }
      }
      );

      await axiosInstance.post(`/faq?$sort[position]=1&for=` + endpoint, {
        action: "orderFAQ",
        order: FAQs.map((faq, index) => ({
          id: faq.id,
          position: index,
        })),
      }).then((response) => {
        if(response.data === "updated successfully"){
          res += 1
        }
      });

      if(res === 2){
        setVisibleSave(false);
        setVisibleLoaderSave(false);
        setAfterSave(true);
        setTimeout(() => {
          setAfterSave(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error updating categories:", error);
    }
  }

  // Handle drag end for FAQs
  const handleFAQDragEnd = async (result: DropResult) => {
    if (!result.destination) return; // Dropped outside the list

    // Reorder the FAQ items in the state
    const reorderedFAQs = Array.from(FAQs);
    const [removedFAQ] = reorderedFAQs.splice(result.source.index, 1);
    reorderedFAQs.splice(result.destination.index, 0, removedFAQ);

    // compare each item in the array of reorderedFAQs with the FAQs array to check if there is a change in position
    let isMatch = true;
    for (let i = 0; i < reorderedFAQs.length; i++) {
      if (reorderedFAQs[i].id !== FAQs[i].id) {
        isMatch = false;
        break;
      }
    }
    if (!isMatch) {
      setVisibleSave(true);
      setFAQs(reorderedFAQs);
    } else {
      setVisibleSave(false);
    }
    
    // Send a POST request to update the server with the new FAQ order
    // try {
    //   await axiosInstance.post(`/faq?$sort[position]=1&for=` + endpoint, {
    //     action: "orderFAQ",

    //     order: reorderedFAQs.map((faq, index) => ({
    //       id: faq.id,
    //       position: index,
    //     })),
    //   });
    // } catch (error) {
    //   console.error("Error updating FAQs:", error);
    // }
  };

  // Function to update an FAQ in the state for editing
  const handleFAQUpdated = (updatedFAQ: FAQ) => {
    const updatedFAQIndex = FAQs.findIndex((faq) => faq.id === updatedFAQ.id);
    if (updatedFAQIndex !== -1) {
      const updatedFAQs = [...FAQs];
      updatedFAQs[updatedFAQIndex] = updatedFAQ;
      setFAQs(updatedFAQs);
    }
  };

  // Function to update an FAQ in the state for editing
  const updateFAQ = () => {
    axiosInstance
      .get(`/faq?$sort[position]=1&for=` + endpoint)
      .then((response) => {
        setFAQs(response.data as FAQ[]);
        setShowFQA(new Array(response.data.length).fill(false));
      })
      .catch((error) => {
        console.error("Error fetching FAQs:", error);
      });
  };

  // Function to update category data
  const updateCategories = () => {
    axiosInstance
      .get(`/categories?$sort[position]=1&for=` + endpoint)
      .then((response) => {
        setPaperLinkFAQs(response.data as PaperLinkFAQ[]);
        setShowPaperLinks(new Array(response.data.length).fill(false));
      })
      .catch((error) => {
        console.error("Error fetching PaperLink FAQs:", error);
      });
  };

  // Handle category update
  const handleCategoryUpdated = (updatedCategory: PaperLinkFAQ) => {
    // Update the category in the state
    const updatedCategories = [...paperLinkFAQs];
    const categoryIndex = updatedCategories.findIndex(
      (category) => category.id === updatedCategory.id
    );

    if (categoryIndex !== -1) {
      updatedCategories[categoryIndex] = updatedCategory;
      setPaperLinkFAQs(updatedCategories);
    }
  };

  const resetData = () => {
    window.location.reload();
    setSuccess(true);
  };

  const refreshData = () => {
    // fetchData();
    resetData();
    // fetchData();
  };

  return (
    
    <Box
      style={{ borderRadius: "0.5rem", border: "0.3px solid #D3E2E4" }}
      p={"lg"}
      bg={"#fff"}
      mt={"lg"}
    >

      { visibleLoaderSave && (
        <div className="absolute z-30 h-full top-0 left-0 w-full bg-white/50">
          <span className="absolute top-[50%] left-[50%]">
            <ClipLoader 
              size={40} 
              color="green"  
              speedMultiplier={0.5}

            />
          </span>{" "}
        </div>
      )}

      {afterSave && (
        <div className="absolute flex border-l-8 top-0 right-0 border-l-green-400 rounded-l-md p-3 my-10 bg-white max-md:w-[250px] lg:w-[350px] shadow-md border">
          <span className="text-green-500 text-sm">
          success <br />
          <span className="text-gray-500 text-sm">
          New order saved
          </span>
          </span>
        </div>
      )}

      <div className="flex items-center justify-between height-[5.3125rem] py-2 rounded-t-lg">
        <h2
          style={{
            fontSize: "1.37rem",
            fontWeight: "400",
          }}
        >
          Questions And Answers (QnA’s)
        </h2>
        <p onClick={() => handleSave()} className={`text-green-500 cursor-pointer ${!visibleSave ? "hidden" : null}`}>save</p>
      </div>

      { success && (
        <div className="absolute flex border-r-8 bottom-0 right-0 border-r-green-400 p-3 my-10 bg-green-50 text-green-400">
          <span className="mx-3">
            <ClipLoader size={20} color="green" />
          </span>{" "}
          Sending your requst. Please wait...
        </div>
      )}

      {modalOne && (
        <FirstModal
          setModalOne={setModalOne}
          handleModalClose={handleModalClose}
          updateCategories={updateCategories}
          endpoint={endpoint}
        />
      )}
      {/**HERE THE MAIN */}
      {loading ? (
        <Loader />
      ) : (
        <>
            <div>
              {/* categories */}
              <div>
                  <Flex justify={"space-between"} align={"center"} my={"sm"}>
                    <h1 className="font-semibold py-3  text-black">
                      Categories
                    </h1>
                    <div className="">
                      <span
                        onClick={handleModalOne}
                        className="cursor-pointer inline-flex items-center justify-center w-12 h-12 flex-shrink-0 fill-current active:bg-slate-300 bg-white rounded-full shadow-drop"
                      >
                        <ActionIcon
                          bg={"#5FA348"}
                          style={{ borderRadius: 40 }}
                          w={40}
                          h={40}
                        >
                          +
                        </ActionIcon>
                      </span>
                    </div>
                  </Flex>
                  <div className="">
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="categories" direction="vertical">
                        {(provided: DroppableProvided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className="flex flex-col gap-2"
                          >
                            {paperLinkFAQs.map((faq, index) => (
                              <Draggable
                                key={faq.id}
                                draggableId={faq.id.toString()}
                                index={index}
                              >
                                {(provided: any) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onClick={() => togglePaperLink(index)}
                                    onDragOver={(e) => handleDragOver(e, index)}
                                    onDrop={() => handleDrop(index)}
                                  >
                                    <div 
                                    className="bg-[#F9FAFB] shadow-sm border rounded-md w-full hover:bg-gray-200 p-2 py-5 flex items-center"
                                  >
                                      <div 
                                        className="w-full"
                                      >
                                        <p className="flex gap-3 w-full">
                                          <span className="ml-2 mt-3">
                                            <Dots />
                                          </span>
                                          <Text
                                            style={{
                                              border: "0.5px solid  #D3E2E4",
                                              width: "100%",
                                              borderRadius: "0.125rem",
                                              padding: "0.6rem",
                                              fontSize: "0.9rem",
                                            }}
                                            bg={"white"}
                                          >
                                            {faq.name}
                                          </Text>
                                        </p>
                                      </div>
                                        <p className="flex gap-5 px-2">
                                          <span
                                            className="cursor-pointer"
                                            onClick={() => setEditCategory(faq)}
                                          >
                                            <EditIcon />
                                          </span>

                                          <span
                                            className="cursor-pointer z-20"
                                            onClick={() =>
                                              {
                                                setToDeleteId(faq.id)
                                                setDeleteType("category")
                                                setDeleteModal(true)
                                                setCategoryValueToDelete(faq.name)
                                              }
                                            }
                                          >
                                            <DeleteIcon />
                                          </span>
                                        </p>
                                      {editCategory && (
                                        <EditCategoryModal
                                          categoryToEdit={editCategory}
                                          onClose={() => setEditCategory(null)}
                                          onCategoryUpdated={(
                                            updatedCategory
                                          ) => {
                                            handleCategoryUpdated(
                                              updatedCategory
                                            );
                                            setEditCategory(null);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
              </div>

              {/*Q%A*/}
              <div>
                <div className="flex items-center justify-between mb-3 mt-2">
                  <h1 className="font-semibold text-black">
                    Questions & Answers
                  </h1>

                  <span
                    onClick={handleModalTwo}
                    className="inline-flex items-center justify-center w-12 h-12 flex-shrink-0 fill-current active:bg-slate-300 bg-white cursor-pointer rounded-full"
                  >
                    <ActionIcon
                      bg={"#5FA348"}
                      style={{ borderRadius: 40 }}
                      w={40}
                      h={40}
                    >
                      +
                    </ActionIcon>
                  </span>
                </div>
                {modalTwo && (
                  <SecondModal
                    setModalTwo={setModalTwo}
                    handleModalClose={handleModalClose}
                    setFAQs={setFAQs}
                    FAQs={FAQs}
                    endpoint={endpoint}
                    onSubmitSuccess={refreshData}
                  />
                )}
                { deleteModal && (
                  <DeleteModal
                  handleDeleteModal={handleDeleteModal}
                  updateFAQ={updateFAQ}
                  updateCategories={updateCategories}
                  categoryValueToDelete={categoryvalueToDelete}
                  endpoint={endpoint}
                  toDeteleId={toDeteleId}
                  deleteType={deleteType}

                  />
                )}
                <div className="">
                  {/* Mapped arrays */}
                  <DragDropContext onDragEnd={handleFAQDragEnd}>
                    <Droppable droppableId="faq-list"  direction="vertical">
                      {(provided: DroppableProvided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="flex flex-col gap-2"
                        >
                                  {FAQs.map((qna, index) => (
                                    <Draggable
                                      key={qna.id}
                                      draggableId={qna.id.toString()}
                                      index={index}
                                    >
                                      {(provided: any) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          onClick={() => togglePaperLink(index)}
                                          onDragOver={(e) => handleDragOver(e, index)}
                                          onDrop={() => handleDrop(index)}
                                        >
                                            <div
                                              className="bg-[#F9FAFB] shadow-sm border rounded-md w-full hover:bg-gray-200 p-2 py-5 gap-3 flex items-center"
                                            >
                                              <span className="ml-2">
                                                <Dots />
                                              </span>
                                              <div className="w-full" onClick={() => toggleFQA(index)}>
                                                <p className="flex gap-3">
                                                  <div className="w-full">
                                                    <Text
                                                      style={{
                                                        border:
                                                          "0.5px solid  #D3E2E4",
                                                        width: "100%",
                                                        borderRadius: "0.125rem",
                                                        padding: "0.6rem",
                                                        fontSize: "0.9rem",
                                                      }}
                                                      bg={"white"}
                                                    >
                                                      {qna.question}
                                                    </Text>
                                                    {showFQA[index] && (
                                                      <Text
                                                      style={{
                                                        border:
                                                          "0.5px solid  #D3E2E4",
                                                        width: "100%",
                                                        borderRadius: "0.125rem",
                                                        padding: "0.6rem",
                                                        fontSize: "0.9rem"
                                                      }}
                                                      bg={"white"}
                                                    >
                                                      <p className="text-gray-500 text-sm">
                                                      Answer:
                                                      </p>
                                                    {qna.answer}
                                                    </Text>
                                                  )}
                                                  </div>
                                                </p>
                                              </div>
                                              <p className="flex gap-5 px-2">
                                                <span
                                                  onClick={() =>
                                                    openEditModal(qna)
                                                  }
                                                  className="cursor-pointer"
                                                >
                                                  <EditIcon />
                                                </span>
                                                <span
                                                  onClick={() =>
                                                    {
                                                      setToDeleteId(qna.id)
                                                      setDeleteType("faq")
                                                      setDeleteModal(true)
                                                    }
                                                    
                                                    // handleDeleteFAQ(qna.id)
                                                  }
                                                  className="cursor-pointer"
                                                >
                                                  <DeleteIcon />
                                                </span>
                                              </p>
                                            </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
              {isEditing && (
                <EditFQAModal
                  faqToEdit={editFAQ}
                  onClose={closeEditModal}
                  onFAQUpdated={handleFAQUpdated}
                />
              )}
            </div>
        </>
      )}
    </Box>
  );
};

export default Qna;