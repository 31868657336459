// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinTwo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}

.opsspin {
  animation: spinTwo 2s linear infinite;
}

.emoji-container {
  position: relative;
  width: 100px;
  height: 50px;
  overflow: hidden;
}

.emoji {
  position: absolute;
  font-size: 2rem;
  animation: bounce 4s infinite linear;
}

.my-component {
  display: none;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.my-component.show {
  display: block;
  opacity: 1;
}

.my-component.hide {
  display: block;
  opacity: 0;
}

.hidden-text {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease;
}

.typing-animation {
  opacity: 1;
  visibility: visible;
  animation: typing 2s steps(40, end);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes bounce {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Paperlink/PaperLink.css"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,mCAAmC;AACrC;;AAEA;EACE;IACE,QAAQ;EACV;EACA;IACE,WAAW;EACb;AACF;AACA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,wBAAwB;EAC1B;AACF","sourcesContent":["@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes spinTwo {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(-360deg);\n  }\n}\n\n.spin {\n  animation: spin 2s linear infinite;\n}\n\n.opsspin {\n  animation: spinTwo 2s linear infinite;\n}\n\n.emoji-container {\n  position: relative;\n  width: 100px;\n  height: 50px;\n  overflow: hidden;\n}\n\n.emoji {\n  position: absolute;\n  font-size: 2rem;\n  animation: bounce 4s infinite linear;\n}\n\n.my-component {\n  display: none;\n  transition: opacity 0.5s ease;\n  opacity: 0;\n}\n\n.my-component.show {\n  display: block;\n  opacity: 1;\n}\n\n.my-component.hide {\n  display: block;\n  opacity: 0;\n}\n\n.hidden-text {\n  opacity: 0;\n  visibility: hidden;\n  transition: opacity 0.5s ease;\n}\n\n.typing-animation {\n  opacity: 1;\n  visibility: visible;\n  animation: typing 2s steps(40, end);\n}\n\n@keyframes typing {\n  from {\n    width: 0;\n  }\n  to {\n    width: 100%;\n  }\n}\n@keyframes bounce {\n  0% {\n    transform: translateX(0);\n  }\n  50% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
