import { useEffect, useState } from "react";
import { UserData } from "../../pages/Paperlink/resources/TeamInfor";
import axiosInstance from "../../utils/axiosInstance";

interface DataValue {
  data?: Object;
  limit?: number;
  skip?: number;
  total: number;
}
const useTeamsApi = (
  searchValue: string,
  searchFilter: string,
  page?: number
) => {
  const [users1, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<DataValue>({total:0});


  let teamsUserUrl =
   `/teammembers?$sort[createdAt]=-1&`;

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");

    // Set up Axios headers with the token
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    if (searchValue) {
      teamsUserUrl += `$or[0][companyName][$like]=%${searchValue}%&$or[1][companyEmail][$like]=%${searchValue}%&$or[2][teamMemberEmail][$like]=%${searchValue}%`;
    }
    // Use your Axios instance to make authenticated requests
    axiosInstance
       .get<{ data: UserData[] }>(teamsUserUrl)
      // .get(
      //   `/teammembers?$or[0][companyName][$like]=${searchValue}%&$or[1][companyEmail][$like]=${searchValue}%&$or[2][teamMemberEmail][$like]=${searchValue}%&$or[3][status][$like]=${searchValue}%`
      // )

      .then((res) => {
        if(searchFilter) {
          const val = searchFilter === "active" ? true : searchFilter === "pending" ? false : searchFilter;
          const filteredData = res.data.data.filter((user:any) => user.user.isEmailVerified === val);
          setUsers(filteredData);
          setData({ data: filteredData, total: filteredData.length });
          setError(null);
        }else {
          setUsers(res.data.data);
          // .filter((user:any) => user.companyEmail !== user.teamMemberEmail) as UserData[]
          setData({ data: res.data.data, total: res.data.data.length });
          setError(null);
        }
      })
      .catch((err) => {
        setError("There was an error fetching data.");
        console.log("there was an issue: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchFilter, page, searchValue]);

  return {
    loading,
    users1,
    error,
    searchValue,
    data,
    // selectedFilter,
  };
};

export default useTeamsApi;

//selectedFilter is useless now but can be usefull in the future just in case she saids she need two quary at once