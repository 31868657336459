import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import { SubscriptionData } from "../Subscription";
import Loader from "../Loader";

interface Tab2Props {
  selectedUser: any | undefined;
  users: any[];
}

const Tab2: React.FC<Tab2Props> = ({ selectedUser }) => {
  const [users, setUsers] = useState<SubscriptionData[]>([]);
  const [count, setCount] = useState<number>(1);
  const [papercount, setPaperCount] = useState<number>(1);
  const [fillablecount, setFillableCount] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [WhiteGloveService, setWhiteGloveService] = useState<number>(1);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };
  const incrementPaperCount = () => {
    setPaperCount(papercount + 1);
  };

  const decrementPaperCount = () => {
    if (papercount > 0) {
      setPaperCount(papercount - 1);
    }
  };
  const incrementFillableCount = () => {
    setFillableCount(fillablecount + 1);
  };

  const decrementFillableCount = () => {
    if (fillablecount > 0) {
      setFillableCount(fillablecount - 1);
    }
  };
  const incrementWhiteCount = () => {
    setWhiteGloveService(WhiteGloveService + 1);
  };
  const decrementWhiteCount = () => {
    if (WhiteGloveService > 0) {
      setWhiteGloveService(WhiteGloveService - 1);
    }
  };

  useEffect(() => {
    setLoading(true);

    axiosInstance
      .get<{ data: SubscriptionData[] }>(
        `/subscriptions?userId=${selectedUser.userId}&$sort[createdAt]=-1`
      )
      .then((res) => {
        const singleSubscription = [res.data.data[0]] as SubscriptionData[];
        setUsers(singleSubscription);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Th+ere was an error fetching data:", err);
      });
  }, []);

  const filteredUsers = users.filter(
    (user) => selectedUser.userId === user.userId
  );

  return (
    <div className="text-xs md:text-sm  rounded-b-lg rounded-[19.097px] bg-white shadow-md">
      {loading ? (
        <Loader />
      ) : (
        <div>
          {filteredUsers.map((user) => (
            <div key={user.id} className="">
              {/* First div and starting */}
              <div className="grid border-b grid-cols-4 py-6 px-3">
                <div className="grid-item border-none">
                  <h1 className="font-normal text-[0.915rem] text-black">
                    Business Page
                  </h1>
                </div>
                <div className="grid-item bg-[#77C360] rounded-[8px] border border-[#77C360] text-center m-auto ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      d="M22 8.71875L11 19.7188L6 14.7188"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="grid-item bg-[#77C360] rounded-[8px] border border-[#77C360] text-center m-auto ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 28 29"
                    fill="none"
                  >
                    <path
                      d="M22 8.71875L11 19.7188L6 14.7188"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="grid-item text-end md:mr-12">$10.00</div>
              </div>
              {/* Second div */}
              <div className="grid border-b grid-cols-4 py-5 px-3">
                {/* ... Other content */}
                <div className="grid-item border-none">
                  <h1 className=" font-normal text-[0.915rem]  text-black">
                    Team Member
                  </h1>
                </div>
                <div className="grid-item border-none text-center">
                  $2.00
                </div>
                <div className="grid-item text-center ">
                  <div className=" flex items-center py-[0.2rem] justify-center  bg-counter w-24 mx-auto rounded-md text-black">
                    <button
                      className="w-16 py-1 text-[1rem] hover:bg-gray-100 hover:rounded-md "
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <div className="bg-white w-20 py-1 border rounded-md text-center">
                      {count}
                    </div>

                    <button
                      className="w-16 py-1 text-[1rem]  hover:bg-gray-100 hover:rounded-md z-10"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="grid-item text-end  md:mr-12">
                  ${count * 2}.00
                </div>
              </div>
              {/* Third div */}
              <div className="grid border-b grid-cols-4 py-5 px-3">
                {/* ... Other content */}
                <div className="grid-item border-none">
                  <h1 className="font-normal  text-black text-[0.915rem] flex">
                    Paperlink Page
                  </h1>
                </div>
                <div className="grid-item border-none text-center">
                  $2.00
                </div>
                <div className="grid-item text-center">
                  <div className=" flex justify-center items-center py-[0.2rem]  bg-counter w-24 mx-auto rounded-md text-black">
                    <button
                      className="w-16 py-1 text-[1rem] hover:bg-gray-100 hover:rounded-md "
                      onClick={decrementPaperCount}
                    >
                      -
                    </button>
                    <div className="bg-white w-20 py-1 border rounded-md text-center">
                      {papercount}
                    </div>

                    <button
                      className="w-16 py-1 text-[1rem]  hover:bg-gray-100 hover:rounded-md z-10"
                      onClick={incrementPaperCount}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="grid-item text-end  md:mr-12">
                  ${papercount * 2}.00
                </div>
              </div>
              {/* Fourth div (1) */}
              <div className="grid grid-cols-4 py-5 px-3 w-full">
                {/* ... Other content */}
                <div className="grid-item border-none">
                  <h1 className="font-normal text-[0.915rem]  text-black flex">
                    Fillable PDF
                    <span className="text-[11px] md:flex hidden text-[#707070] ml-2">
                      (One time charge)
                    </span>
                  </h1>
                </div>
                <div className="grid-item border-none text-center">
                  <p className="">$5.00</p>
                </div>
                <div className="grid-item text-center ">
                  <div className=" flex justify-center items-center py-[0.2rem]  bg-counter w-24 mx-auto rounded-md text-black">
                    <button
                      className="w-16 py-1 text-[1rem] hover:bg-gray-100 hover:rounded-md "
                      onClick={decrementFillableCount}
                    >
                      -
                    </button>
                    <div className="bg-white w-20 py-1 border rounded-md text-center">
                      {fillablecount}
                    </div>

                    <button
                      className="w-16 py-1 text-[1rem]  hover:bg-gray-100 hover:rounded-md z-10"
                      onClick={incrementFillableCount}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="grid-item text-end md:mr-12">
                  ${fillablecount * 5}.00
                </div>
              </div>
              {/* Fourth div (2) */}
              <div className="grid border-b border-[#BABABA]  grid-cols-4 py-5 pb-7 px-3 text-sm">
                {/* ... Other content */}
                <div className="grid-item border-none  ">
                  <div className=" flex items-center">
                    <h1 className="whitespace-nowrap font-normal text-[0.915rem] flex ">
                      White Glove Service
                      <span className="text-[11px] whitespace-nowrap hidden md:flex text-[#707070] ml-2 ">
                        (One time charge)
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="grid-item border-none text-center">
                  $1.00
                </div>
                <div className="grid-item text-center">
                  <div className=" flex justify-center items-center py-[0.2rem]  bg-counter w-24 mx-auto rounded-md text-black">
                    <button
                      className="w-16 py-1 text-[1rem] hover:bg-gray-100 hover:rounded-md "
                      onClick={decrementWhiteCount}
                    >
                      -
                    </button>
                    <div className="bg-white w-20 py-1 border rounded-md text-center">
                      {WhiteGloveService}
                    </div>

                    <button
                      className="w-16 py-1 text-[1rem]  hover:bg-gray-100 hover:rounded-md"
                      onClick={incrementWhiteCount}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="grid-item  text-end md:mr-12">
                  ${WhiteGloveService * 1}.00
                </div>
              </div>
              {/* The div for end */}
              <div className="grid grid-cols-2 py-6 px-3 shadow-full rounded-b-lg">
                {/* ... Other content */}
                <div className="grid-item text-start">
                  <h1 className="font-normal md:font-bold text-[1.2rem] ">
                    Total Amount
                  </h1>
                </div>
                <div className="grid-item  text-end md:mr-10 lg:ml-10 text-[1.2rem]  font-normal md:font-bold ">
                  $
                  <span>
                    {papercount * user.paperlink +
                      fillablecount * user.fillablePdf +
                      count * user.teamMembers +
                      WhiteGloveService * user.whiteGloveService +
                      10}
                    .00
                  </span>
                </div>
              </div>
            </div>
          ))}
          {filteredUsers.length < 1 && (
            <div className="flex justify-center mt-1 bg-slate-100 text-gray-400 w-full text-xs md:text-sm p-2 items-center align-middle h-full">
              Sorry, No Subscription available for this user!
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tab2;
