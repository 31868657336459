import React, { useState } from "react";
import Lottie from "lottie-react";
import add from "../../../assests/add.json";

const Add = ({ activeTab, setModalOne }) => {
  return (
    <div className="h-[50vh] w-full mx-auto flex items-center justify-center text-center font-extralight mt-3 flex-col ">
      <h2 className="font-extralight">
        Sorry, No Records Found For
        <span className="underline mx-2 ring-offset-1  cursor-pointer">
          {activeTab}
        </span>
        ,Try adding some information
      </h2>
    </div>
  );
};
export default Add;
