
import { useEffect, useState, useCallback } from "react";
import { UsersInfo } from "../../pages/Paperlink/resources/UsersInfo";
import axios from "axios";

interface DataValue {
  data?: Object;
  limit?: number;
  skip?: number;
  total: number;
}

const useFetchUsers = (
  searchValue: string,
  selectedFilter: string,
  selectedStatusFilter: string,
  startDate?: string | null ,
  endDate?: string | null,
  page?: number
) => {
  const [users, setUsers] = useState<UsersInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDatePicked, setIsDatePicked] = useState<boolean | null>(null);
  const [data, setData] = useState<DataValue>({total:0});

  const datefilter =
    startDate 
      ? `&createdAt[$gte]=${startDate || ""}&createdAt[$lte]=${endDate || ""}`
      : "";

      let userUrl =
      `${process.env.REACT_APP_API_URL}/files?$sort[createdAt]=-1&${datefilter}` ||
      `${process.env.REACT_APP_API_URL}/files?$sort[createdAt]=-1&fileAction=${selectedFilter}&${datefilter}` ||
      `/files?$sort[createdAt]=-1&user.email[$like]=${searchValue}` ;

      userUrl +=
      selectedStatusFilter === "added"? `&isDeleted=${0}`:
      selectedStatusFilter === "removed"? `&isDeleted=${1}`: 
      selectedFilter === "complete"? `&fileAction=${selectedFilter}`: 
      selectedFilter === "sign"? `&fileAction=${selectedFilter}`: 
      selectedFilter === "confirm"? `&fileAction=${selectedFilter}`: 
      "";
    
  if (searchValue) {
    userUrl += `&$or[0][fileName][$like]=%${searchValue}%&$or[2][userEmail][$like]=${searchValue}%`;
  }

  useEffect(() => {

    setLoading(true);
    axios
      .get(`${userUrl}&$skip=${page}&$limit=10`)
      .then((res) => {
        setUsers(res.data.data);
        setData(res?.data)
        setError(null);
      })
      .catch((err) => {
        setError("There was an error fetching data.");
        console.log("there was an issue: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchValue, selectedFilter, selectedStatusFilter, page, startDate]);

  return {
    loading,
    users,
    error,
    userUrl,
    searchValue,
    isDatePicked,
    setIsDatePicked,
    data
  };
};

export default useFetchUsers;
