import Dash from "../components/svg-icons/Dash";
import Users from "../components/svg-icons/Users";
import FileIcon from "../components/svg-icons/FileIcon";
import AcctIcon from "../components/svg-icons/AcctIcon";
//import { ReactComponent as AcctIcon } from '../components/svg-icons/AcctIcon';
import Download from "../components/svg-icons/Download";
import Question from "../components/svg-icons/Question";
import { Link } from "react-router-dom";
import TeamsIcon from "../components/svg-icons/TeamsIcon";
import faqIcon from "../assests/paperlink/icons/faq-icon.png";
import teamsIcon from "../assests/paperlink/icons/teams-icon.svg";
import accountsIcon from "../assests/paperlink/icons/accounts-icon.svg";

import accountsReceivableIcon from "../assests/paperlink/icons/accounts-receivable-icon.svg";

// const fill = active ? "white" : "#707070"; // Set fill color to white when active

export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/PaperLink", //
    icon: (
      <Link to="/PaperLink">
        <Dash />
      </Link>
    ),
  },
  {
    key: "User",
    label: "User Log",
    path: "/User",
    icon: (
      <Link to="/User">
        <Users />
      </Link>
    ),
  },
  {
    key: "File",
    label: "File Manager",
    path: "/File",
    icon: (
      <Link to="/File">
        <FileIcon />
      </Link>
    ),
  },
  {
    key: "Accounts",
    label: "Accounts",
    path: "/Accounts",
    icon: (
      <Link to="/Accounts">
        <img src={accountsIcon} alt="" />
      </Link>
    ),
  },
  {
    key: "Teams",
    label: "Teams",
    path: "/Teams",
    icon: (
      <Link to="/Teams">
        <img src={teamsIcon} alt="" />
      </Link>
    ),
  },

  {
    key: "accountRecieve",
    label: "Account Receivable",
    path: "/AccountRecieve",
    icon: (
      <Link to="/AccountRecieve">
        <img src={accountsReceivableIcon} alt="" />
      </Link>
    ),
  },
  {
    key: "Qna",
    label: "FAQ's",
    path: "/Qna",
    icon: <img src={faqIcon} alt="" />,
  },
];
