import React, { useState, ChangeEvent } from "react";
import AcctIcon from "./svg-icons/AcctIcon";
import zone from "../assests/zone.png";
import location from "../assests/location.png";
import "../assests/styles/tab.css";
import Arrow from "./svg-icons/Arrow";
import { useAppContext } from "../AppConext";
import axios from "axios";


interface Option {
  value: string;
  label: string;
}

interface AppContext {
  setActiveTab: (tab: string) => void;
  setUserPlayload: (payload: any) => void;
  setTabs: (payload: string) => void;
}


const options: Option[] = [
  { value: "new_trial", label: "New Trial" },
  { value: "active", label: "active" },
  { value: "Pause", label: "Pause" },
  { value: "Suspend", label: "Suspend" },
  { value: "Cancel", label: "Cancel" },
  { value: "Delete", label: "Delete" },
];
// Sorting options alphabetically
options.sort((a, b) => a.label.localeCompare(b.label));

const Tab1 = () => {
  const { setActiveTab, setUserPlayload, setTabs } = useAppContext() as AppContext;
  const [errorMsg, setErrorMsg] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);




  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    phone: "",
    lastName: "",
    companyName: "",
    industry: "",
    counselorId: "",
    status: "new_trial",
  });


  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/users/?email=${formData.email}`).then(async (res) => {
      setLoading(false);
      if (res.data.total > 0) {
        setErrorMsg(true);
        return;
      }
      else if(res.data.total === 0){
        setUserPlayload(formData);
        setActiveTab("tab2");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleCancel = () => {
    setTabs("tab");
  }


  return (
    <div className="h-auto overflow-hidden font-Poppins pb-[20px] ">
  <form
    className="space-y-4  md:p-5 md:px-0 md:w-full relative"
    onSubmit={handleSave}
  >
    <div className="inline-block md:flex md:justify-between mr-6 ml-6  w-full items-center text-center justify-center">
      <div className="mt-4 flex gap-5 w-full md:w-auto  items-center mb-5 justify-center">
        <label
          htmlFor="select"
          className="text-[#606060] font-Poppins text-20 font-normal"
        >
          Status:
        </label>
        <div className="relative cursor-pointer">
          <select
            id="select"
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="bg-[#fbf5f5] text-[#FF7373] pr-[2.5rem] rounded-lg outline-none px-5 py-2  focus:ring-0 w-full font-[500] p-0 shadow-sm sm:text-sm appearance-none new-custom-select cursor-pointer"
          >
            <option className="text-gray-700" value="new_trial">
              New Trial
            </option>
            <option className="text-green-700" value="active">
              active
            </option>
            <option className="text-blue-400" value="Pause">
              Pause
            </option>
            <option className="text-purple-700" value="Cancel">
              Cancel
            </option>
            <option className="text-red-700" value="Delete">
              Delete
            </option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 !text-[#707070]">
            <Arrow />
          </div>
        </div>
      </div>

      <div className="flex gap-2  items-center">
        <span className="inline-flex cursor-pointer items-center justify-center md:items-left h-20 w-20 md:w-12 md:h-12 flex-shrink-0 fill-current bg-[#E9E9E9] rounded-full shadow-drop mr-20 ">
          <AcctIcon />
        </span>
      </div>
    </div>

    <div className="block w-full md:flex md:flex-wrap md:mx-4 md:text-left  text-center ">
      <div className="md:w-1/2 px-4 mb-2">
        <label htmlFor="email" className={`block py-5 pb-3  ${errorMsg ? "text-red-600" : "text-[#606060]" }`}>
          Email address:
        </label>
        <input
          type="email"
          name="email"
          placeholder=""
          value={formData.email}
          onChange={handleChange}
          className={`md:w-full px-2 py-2 border-b  bg-white focus:outline-none focus:border-[#adadad] focus:shadow-sm ${errorMsg ? "border-red-500" : "border-[#DADADA]" }`}
          required
        />
        {errorMsg && (
          <p className="text-red-500 text-sm mt-2">Email already exist</p>
        )}
      </div>
      <div className="md:w-1/2 px-4 mb-2">
        <label htmlFor="companyName" className="block py-5 pb-3 text-[#606060]">
          Business name:
        </label>
        <input
          type="text"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          className="md:w-full px-2 py-2 border-b border-[#DADADA] bg-white focus:outline-none focus:border-[#adadad] focus:shadow-sm"
          required
        />
      </div>
      <div className="md:w-1/2 px-4 mb-4">
        <label htmlFor="contactName" className="block py-5 pb-3 text-[#606060]">
          Contact name:
        </label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          className="md:w-full px-2 py-2 border-b border-[#DADADA] bg-white focus:outline-none focus:border-[#adadad] focus:shadow-sm"
          required
        />
      </div>
      <div className="md:w-1/2 px-4 mb-4">
        <label
          htmlFor="contactNumber"
          className="block py-5 pb-3 text-[#606060]"
        >
          Contact number:
        </label>
        <input
          type="text"
          name="phone"
          className="md:w-full px-2 py-2 border-b border-[#DADADA] bg-white focus:outline-none focus:border-[#adadad] focus:shadow-sm"
          value={formData.phone}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mt-3 w-full flex justify-between items-center">
        <div className=" md:w-1/2 px-4 mb-6 flex items-center space-x-2">
          <img src={location} alt="google map location" className="mr-2" />
          <span className="text-[#606060]">Google maps location</span>
        </div>
        <div
          className=" md:w-1/2 px-4 mb-6 flex items-center space-x-2"
        >
          <img src={zone} alt="zone-location" className="mr-2" />
          <span className="text-[#606060]">Time Zone Database</span>
        </div>
      </div>
    </div>
    {/* {success && (
              <div className="fixed inset-0 flex items-center justify-center z-50 animate-fade-in ">
                <div className="bg-black opacity-70 inset-0 absolute h-[110vh] -top-5" />

                <div className="bg-white p-8 rounded-lg text-center shadow-lg relative z-10">
                  <div className="w-full flex justify-center items-center mb-2">
                    <BsCheckCircleFill color="green" size={50} />
                  </div>
                  <p className="text-green">SUCCESSFULLY UPDATED!</p>
                  <button
                    // onClick={}
                    className="bg-green-300 text-white px-4 py-2 rounded-md hover:bg-green-500 mt-4"
                  >
                    clear
                  </button>
                </div>
              </div>
            )} */}
    {/* {errorMsg && (
              <div className="fixed inset-0 flex items-center justify-center z-50 animate-fade-in ">
                <div className="bg-black opacity-70 inset-0 absolute h-[110vh] -top-5" />

                <div className="bg-white p-5 rounded-lg text-center shadow-lg relative z-10">
                  <div className="w-[50%] mx-auto flex justify-center items-center mb-2">
                    <TfiFaceSad color="red" size={50} />
                  </div>
                  <p className="text-red-500 flex w-[70%] mx-auto items-center justify-center">
                    Ops something went wrong, check your inputs and try again!
                  </p>
                  <button
                    // onClick={}
                    className="bg-red-300 text-white px-4 py-2 rounded-md hover:bg-red-500 mt-4"
                  >
                    clear
                  </button>
                </div>
              </div>
            )} */}
    <div className="flex !mt-20 md:gap-10 gap-5 md:px-7  md:pb-5 flex-col md:flex-row items-center md:items-start ">
      <>
      <button
        typeof="submit"
        className={`Tab outline-none transition-all font-[500] duration-100 ease-in hover:bg-[#77C360] active:bg-[#77C360] ${loading && "bg-slate-500"} `}>
         {loading ? "Loading..." : "Next"}
        </button>
        <button          
          onClick={() => {handleCancel()}}
         className="btnT transition-all duration-100 ease-in font-[500] outline-none hover:border-[#FF7373]">
          Cancel
        </button>
      </>
    </div>
  </form>
</div>

  );
};

export default Tab1;