import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTabLabel, selectActiveTabLabel } from "../../store/tab-slice";
import { selectSelectedLinkKey } from "../../store/selectedLinkKeySlice"; // Import the selector for SelectedLinkKey

const linksmall =
  "flex items-center gap-2 font-light px-3 py-2 hover:no-underline text-base";

const activeLinkStyle = "bg-[#222529] text-white";
const defaultLinkStyle = "text-neutral-400";

interface Tab {
  label: string;
  path: string;
  id: number;
}

const TABS: Tab[] = [
  { label: "Paperlink", path: "/PaperLink", id: 1 },
  { label: "Signinlink", path: `https://signinlink.app/login?email=hello@paperdaz.com&pass=Paperless2024!&prompt=true`, id: 2 },
  // { label: "Timelink", path: "/TimeLayout", id: 3 },
  // { label: "RXlink", path: "/RxLayout", id: 4 },
  // { label: "Hostlink", path: "/HostLayout", id: 5 },
];

const QnAJunologix: Tab[] = [
  { label: "Junologix", path: "/Junologix", id: 1 },
  { label: "TaxIO", path: "/TaxIO", id: 2 },
  { label: "DataIO", path: "/DataIO", id: 3 },
  { label: "ScheduleIO", path: "/ScheduleIO", id: 4 },
];

export default function Toplinks() {
  const activeTab = useSelector(selectActiveTabLabel);
  const selectedLinkKey = useSelector(selectSelectedLinkKey);

  const dispatch = useDispatch();

  const handleTabClick = (tab: Tab) => {
    dispatch(setActiveTabLabel(tab.label));
  };

  return (
    <div className=" items-center flex justify-center md:justify-start mb-3">
      <div className="md:flex w-[90%] mx-1 md:mx-6 mt-[100px] md:mt-[25px] md:bg-white md:w-[300px] overflow-hidden rounded-[10px] flex md:h-[45px] md:font-poppins md:justify-between md:align-center">
        {selectedLinkKey === "QnAJunologix" ||
        activeTab === "Junologix" ||
        activeTab === "TaxIO" ||
        activeTab === "DataIO" ||
        activeTab === "ScheduleIO"
          ? QnAJunologix.map((tab: Tab) => {
              return (
                <button
                  key={tab.path}
                  onClick={() => handleTabClick(tab)}
                  className={`flex ${linksmall} ${
                    activeTab === tab.label ? activeLinkStyle : defaultLinkStyle
                  } flex-1 justify-center text-[0.8rem] items-center`}
                >
                  {tab.label}
                </button>
              );
            })
          : TABS.map((tab: Tab) => {
            if(tab.label === "Paperlink")  
            return (
                <button
                  key={tab.path}
                  onClick={() => handleTabClick(tab)}
                  className={`flex ${linksmall} ${
                    activeTab === tab.label ? activeLinkStyle : defaultLinkStyle
                  } flex-1 justify-center text-[0.861rem] font-[500] items-center`}
                >
                  {tab.label}
                </button>
              );
            else
            return (
              <button
                key={tab.path}
                onClick={() => window.open(tab.path, "_blank")}
                className={`flex ${linksmall} ${
                  activeTab === tab.label ? activeLinkStyle : defaultLinkStyle
                } flex-1 justify-center text-[0.861rem] font-[500] items-center`}
              >
                {tab.label}
              </button>
            );  
            })}
      </div>
    </div>
  );
}
