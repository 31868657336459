// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shadow-custom {
  box-shadow: 0 5px 7px 2px rgba(95, 201, 63, 0.25);
}
.active-tab {
  box-shadow: 0 5px 7px 2px rgba(95, 201, 63, 0.25);
}
`, "",{"version":3,"sources":["webpack://./src/components/PaperlinkShared/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,iDAAiD;AACnD;AACA;EACE,iDAAiD;AACnD","sourcesContent":[".shadow-custom {\n  box-shadow: 0 5px 7px 2px rgba(95, 201, 63, 0.25);\n}\n.active-tab {\n  box-shadow: 0 5px 7px 2px rgba(95, 201, 63, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
