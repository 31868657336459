import React, { useState } from "react";
import { TfiClose } from "react-icons/tfi";
import axiosInstance from "../../utils/axiosInstance";
import icon from "../../assests/warning-icon.svg";

interface DeleteModalProps {
  handleDeleteModal: () => void;
  updateFAQ: () => void;
  categoryValueToDelete: string;
  toDeteleId: any;
  endpoint: string;
  deleteType: string;
  updateCategories: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  categoryValueToDelete,
  deleteType,
  toDeteleId,
  updateFAQ,
  updateCategories,
  handleDeleteModal,
  endpoint,
}) => {

    // Deleting category icon
    const handleDeleteCategory = (categoryId: any) => {
      axiosInstance
        .delete(`/categories/${categoryId}`)
        .then(() => {
          updateCategories();
          handleDeleteModal();
        })
        .catch((error) => {
          console.error("Error deleting category:", error);
        });
    };

    // Deleting question and answer icon
    const handleDeleteFAQ = (faqId: any) => {
      axiosInstance
        .delete(`/faq/${faqId}`)
        .then(() => {
          updateFAQ();
          handleDeleteModal();
        })
        .catch((error) => {
          console.error("Error deleting FAQ:", error);
        });
    };


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 backdrop-blur-sm"></div>
      <div className="absolute inset-0 bg-black opacity-5"></div>
      <div className="z-10 relative bg-white py-5 rounded-xl shadow-xl min-w-[300px] md:min-w-[400px]">
        <div className="flex justify-end px-5 w-full pb-2">
          <p className="cursor-pointer" onClick={handleDeleteModal}>
            <TfiClose />
          </p>
        </div>
        <div className="p-5 w-full flex flex-col items-center text-center">
          <img src={icon} alt="warning icon" />
          <div className="flex flex-col gap-3 items-center">
            <div>
            <p className="text-base">Are you sure you want to delete</p>
            <h1 className="font-bold text-lg pt-2">{deleteType === "category" ? categoryValueToDelete + " ?" :  "'Visitors' Question ?"}</h1>
          </div>
          <div className="max-w-[300px]">
            <p className="text-xs">When you delete this question, the answer will also be deleted</p>
          </div>
          </div>

          <div className="w-full align-middle items-center justify-center flex mt-10 mb-5 ">
            <button
              className="px-8 py-2 text-red-500 rounded"
              onClick={() => deleteType === "category" ? handleDeleteCategory(toDeteleId) : handleDeleteFAQ(toDeteleId)}
            >
              Delete
            </button>
            {/* cance */}
            <button
              className="px-8 py-2 text-white bg-[#75C05F] rounded"
              onClick={handleDeleteModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
