export default function Dash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="#ffff"
    >
      <g clipPath="url(#clip0_16913_1299)">
        <path
          d="M7.91667 6.66667H1.25C0.560833 6.66667 0 6.10583 0 5.41667V1.25C0 0.560833 0.560833 0 1.25 0H7.91667C8.60583 0 9.16667 0.560833 9.16667 1.25V5.41667C9.16667 6.10583 8.60583 6.66667 7.91667 6.66667ZM1.25 0.833333C1.02083 0.833333 0.833333 1.02 0.833333 1.25V5.41667C0.833333 5.64667 1.02083 5.83333 1.25 5.83333H7.91667C8.14583 5.83333 8.33333 5.64667 8.33333 5.41667V1.25C8.33333 1.02 8.14583 0.833333 7.91667 0.833333H1.25Z"
          fill="#707070"
        />
        <path
          d="M7.91667 20.0003H1.25C0.560833 20.0003 0 19.4395 0 18.7503V9.58365C0 8.89448 0.560833 8.33365 1.25 8.33365H7.91667C8.60583 8.33365 9.16667 8.89448 9.16667 9.58365V18.7503C9.16667 19.4395 8.60583 20.0003 7.91667 20.0003ZM1.25 9.16698C1.02083 9.16698 0.833333 9.35365 0.833333 9.58365V18.7503C0.833333 18.9803 1.02083 19.167 1.25 19.167H7.91667C8.14583 19.167 8.33333 18.9803 8.33333 18.7503V9.58365C8.33333 9.35365 8.14583 9.16698 7.91667 9.16698H1.25Z"
          fill="#707070"
        />
        <path
          d="M18.7497 20.0004H12.083C11.3938 20.0004 10.833 19.4396 10.833 18.7504V14.5837C10.833 13.8946 11.3938 13.3337 12.083 13.3337H18.7497C19.4388 13.3337 19.9997 13.8946 19.9997 14.5837V18.7504C19.9997 19.4396 19.4388 20.0004 18.7497 20.0004ZM12.083 14.1671C11.8538 14.1671 11.6663 14.3537 11.6663 14.5837V18.7504C11.6663 18.9804 11.8538 19.1671 12.083 19.1671H18.7497C18.9788 19.1671 19.1663 18.9804 19.1663 18.7504V14.5837C19.1663 14.3537 18.9788 14.1671 18.7497 14.1671H12.083Z"
          fill="#707070"
        />
        <path
          d="M18.7497 11.6667H12.083C11.3938 11.6667 10.833 11.1058 10.833 10.4167V1.25C10.833 0.560833 11.3938 0 12.083 0H18.7497C19.4388 0 19.9997 0.560833 19.9997 1.25V10.4167C19.9997 11.1058 19.4388 11.6667 18.7497 11.6667ZM12.083 0.833333C11.8538 0.833333 11.6663 1.02 11.6663 1.25V10.4167C11.6663 10.6467 11.8538 10.8333 12.083 10.8333H18.7497C18.9788 10.8333 19.1663 10.6467 19.1663 10.4167V1.25C19.1663 1.02 18.9788 0.833333 18.7497 0.833333H12.083Z"
          fill="#707070"
        />
      </g>
      <defs>
        <rect width="20" height="20" fill="white" stroke="#ffff" />
      </defs>
    </svg>
  );
}
