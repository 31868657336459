import { useEffect, useState, useCallback } from "react";
import { Billing } from "../../pages/Paperlink/resources/Billing";
import axios from "axios";

interface DataValue {
  data?: Object;
  limit?: number;
  skip?: number;
  total: number;
}

const useBilling = (
  searchValue: string,
  selectedFilter: string,
  startDate?: string | null ,
  endDate?: string | null,
  page?:number
) => {
  const [users, setUsers] = useState<Billing[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDatePicked, setIsDatePicked] = useState<boolean | null>(null);
  const [data, setData] = useState<DataValue>({total:0});

  const datefilter =
    startDate 
      ? `&createdAt[$gte]=${startDate || ""}&createdAt[$lte]=${endDate || ""}`
      : "";

  const userUrl = searchValue
    ? `${process.env.REACT_APP_API_URL}/billings?$or[0][companyName][$like]=${searchValue}%&$or[1][companyEmail][$like]=${searchValue}%`
    : `${process.env.REACT_APP_API_URL}/billings?${datefilter}`;
  useEffect(() => {
    setLoading(true);
    
    axios
      .get(`${userUrl}&$skip=${page}&$limit=10`)
      .then((res) => {
        setData(res.data);
        setUsers(res.data.data);
        setError(null);
      })
      .catch((err) => {
        setError("There was an error fetching data.");
        console.log("there was an issue: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchValue, page, selectedFilter, startDate]);

  return {
    loading,
    users,
    error,
    searchValue,
    isDatePicked,
    setIsDatePicked,
    data,
  };
};

export default useBilling;
