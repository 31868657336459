// Loader.js
import React from "react";
import { PulseLoader } from "react-spinners";

const Loader = ({ color = "green", size = 80 }) => {
  return (
    <div className="flex flex-col justify-center gap-4 items-center mt-[20%] md:mt-[5%]  md:h-[200px]">
      <h1 className="text-[#77C360] text-[1.1rem] font-[300]">Loading...</h1>
      <PulseLoader size={10} speedMultiplier="0.6" color="#77C360" />
    </div>
  );
};

export default Loader;
