import React, { useState } from "react";
import Tab1 from "../components/Tab1AddAccount";
import Tab2 from "../components/Tab2AddAccount";
import Tabs from "../components/TabsAddAccount";
import { useAppContext } from "../AppConext";

interface TabrowProps {
  user: any; // Replace 'YourUserType' with the actual type of your users
}

const TabrowAddAccount: React.FC<TabrowProps> = () => {

  const { activeTab } = useAppContext() as { activeTab: string };

  return (
    <div className="!border-radius-[0.9375rem] bg-white w-1852  h-auto overflow-hidden font-Poppins">
      <div className="bg-secondaryColor flex flex-col justify-between height-[5.3125rem] px-4 py-2 rounded-t-lg pb-0">
        <div className="hidden md:inline border-b-0 px-1 pt-2 text-[#707070] !font-[500] leading-normal text-lg md:text-[1.2rem]">
          Accounts
        </div>
        <div className="flex w-full justify-end items-baseline">
          <div className="flex justify-between  items-baseline w-[80%] mx-auto end-0 h-full pt-0">
            <div className="flex justify-between gap-4 md:gap-5 border-b w-full text-sm md:text-[15px] ">
              <p
                className={`flex ml-10 pb-1 ${
                  activeTab === "tab1"
                    ? "border-b-4 rounded overflow-hidden border-[#222529] font-extrabold "
                    : "text-gray-500"
                }`}
              >
                <p className="md:inline hidden mr-2">Account </p> Profile
              </p>
              <p
                className={`mr-12 pb-1 ${
                  activeTab === "tab2"
                    ? "border-b-4 rounded overflow-hidden border-[#222529] "
                    : "text-gray-500"
                }`}
              >
                Subscription
              </p>
              <p
                className={`mr-9 pb-1 ${
                  activeTab === "tab3"
                    ? "border-b-4 rounded overflow-hidden border-[#222529] "
                    : "text-gray-500"
                }`}
              >
                Invoice
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Tabs for mobile view */}
      <div className="md:hidden  w-full ">
        <div className="flex justify-between  w-full h-auto"></div>
      </div>

      {activeTab === "tab1" && (
        <div className="">
          <Tab1/>
        </div>
      )}
      {activeTab === "tab2" && (
        <div>
          {/* Content for Tab 2 */}
          {/* Your tab 2 content here */}
          <Tab2/>
          
        </div>
      )}

      {activeTab === "tab3" && (
        <div>
          {/* Content for Tab 3 */}
          {/* Your tab 3 content here */}
          <Tabs/>
        </div>
      )}
    </div>
  );
};

export default TabrowAddAccount;
