import { useEffect, useState } from "react";
import { Ledger } from "../../pages/Paperlink/resources/Ledger";
import axiosInstance from "../../utils/axiosInstance"; // Import your Axios instance

interface DataValue {
  data?: Object;
  limit?: number;
  skip?: number;
  total: number;
}

const useLedger = (
  searchValue: string,
  selectedFilter: string,
  startDate?: string | null,
  endDate?: string | null,
  page?: number
) => {
  const [users, setUsers] = useState<Ledger[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDatePicked, setIsDatePicked] = useState<boolean | null>(null);
  const [data, setData] = useState<DataValue>({total:0});

  const datefilter = 
    startDate
    ? `&createdAt[$gte]=${startDate || ""}&createdAt[$lte]=${endDate || ""}`
    : "";

    let userUrl =
    `${process.env.REACT_APP_API_URL}/ledger?$sort[createdAt]=-1&${datefilter}`;
      userUrl +=
      selectedFilter === "complete"? `action=${selectedFilter}`: 
      selectedFilter === "sign"? `action=${selectedFilter}`: 
      selectedFilter === "confirm"? `action=${selectedFilter}`: 
      "";

  if (searchValue) {
    userUrl += `$or[0][guestName][$like]=%${searchValue}%&$or[2][fileOwnerEmail][$like]=%${searchValue}%&$or[3][fileName][$like]=%${searchValue}%`;
  }
  

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axiosInstance
      .get(`${userUrl}&$skip=${page}&$limit=10`)
      .then((res) => {
        setUsers(res.data.data as Ledger[]);
        setData(res.data);
        setError(null);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          // Handle the 401 Unauthorized error here
          setError(
            "Your session has timed out, and you are unauthorized. Try logging in again."
          );
        } else {
          setError(
            "There was an error fetching data. Try refreshing this page."
          );
        }
        console.log("There was an issue: ",error,"!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchValue, selectedFilter, page, startDate, endDate]);

  return {
    loading,
    users,
    error,
    searchValue,
    isDatePicked,
    setIsDatePicked,
    data,
  };
};

export default useLedger;
