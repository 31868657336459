const EditIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
      >
        <path
          d="M11.7498 4.22549C12.3719 3.55145 12.683 3.21443 13.0135 3.01784C13.8111 2.5435 14.7932 2.52875 15.6041 2.97893C15.9401 3.16551 16.2608 3.49304 16.902 4.14811C17.5433 4.80317 17.8639 5.13071 18.0465 5.47401C18.4872 6.30235 18.4728 7.30558 18.0085 8.12033C17.816 8.458 17.4861 8.77576 16.8263 9.41129L8.97549 16.9729C7.72508 18.1772 7.09987 18.7794 6.31849 19.0846C5.53711 19.3898 4.6781 19.3673 2.96009 19.3224L2.72634 19.3163C2.20332 19.3026 1.94181 19.2958 1.7898 19.1233C1.63778 18.9508 1.65854 18.6844 1.70004 18.1516L1.72258 17.8623C1.83941 16.3628 1.89782 15.613 2.19063 14.9391C2.48345 14.2651 2.98853 13.7179 3.99871 12.6234L11.7498 4.22549Z"
          stroke="#707070"
          strokeWidth="1.25229"
          strokeLinejoin="round"
        />
        <path
          d="M10.8535 4.3208L16.6976 10.1648"
          stroke="#707070"
          strokeWidth="1.25229"
          strokeLinejoin="round"
        />
        <path
          d="M11.6885 19.3488L18.3674 19.3488"
          stroke="#707070"
          strokeWidth="1.25229"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
export default EditIcon;
