import React from "react";
import Pagination from "react-js-pagination";
import prevIcon from "../assests/paperlink/icons/prev-paginate-icon.svg";
import nextIcon from "../assests/paperlink/icons/next-paginate-icon.svg";

interface PaginationProps {
  total: number;
  limit?: number;
  onChange: (e: number) => void;
  activePage: number;
}

const PaginationComponent: React.FC<PaginationProps> = ({
  total,
  onChange,
  activePage,
  limit,
}) => {
  return (
    <div className="flex items-center gap-4 justify-center">
      <Pagination
        activePage={activePage}
        itemsCountPerPage={10}
        totalItemsCount={total}
        pageRangeDisplayed={10}
        hideFirstLastPages={true}
        onChange={onChange}
        innerClass="justify-center py-4 items-center flex"
        itemClass="text-white flex items-center justify-center p-[0.1rem] mx-[2.5px] min-w-[35px] min-h-[35px] text-center hover:opacity-90"
        linkClass="text-[#000] text-bold"
        activeClass="bg-[#D9D9D9] text-black rounded-full"
        activeLinkClass="bg-[transparent] text-[#333]"
        prevPageText={<img className="mr-8" src={prevIcon} />}
        nextPageText={<img className="ml-8" src={nextIcon} />}
      />
      <button className="text-sm">View All</button>
    </div>
  );
};

export default PaginationComponent;
