import React, { useEffect, useState, useRef } from "react";
import Calender from "../../assests/Calendar.png";
import SearchGreen from "../../components/svg-icons/SearchGreen";
import { UsersInfo } from "./resources/UsersInfo";
import Loader from "./resources/Loader";
import ErrorMessage from "./resources/ErrorMessage";
import { TfiClose } from "react-icons/tfi";
import SearchBar from "./resources/SearchBar";
import convertDateTime from "./resources/DateConverter";
import useFetchUsers from "../../hooks/APIrequest/useFetchUsers";
import usePagination from "../../hooks/Paginations/usePagination";
import FileTabRow from "./resources/FileTabrow/FileTabRow";
import Arrow from "../../components/svg-icons/Arrow";
import DateRangePickerCalendarExample from "../../hooks/Others/DateRangePicker";

//no record icon
import noRecords from "../../assests/noRecords.json";
import Lottie from "lottie-react";

//redux
import { useSelector, useDispatch } from "react-redux";
import { selectActiveTabLabel, setActiveTabLabel } from "../../store/tab-slice";

//hook for seting tabs to default paperLink
import useCustomActiveTabs from "../../hooks/Others/useCustomActiveTabs";
import Empty from "./resources/Empty";
import DateComponent from "../../components/DateComponent";
import PaginationComponent from "../../components/paginationComponent";
import { useAppContext } from "../../AppConext";

const makeStyle = (status: string) => {
  if (status === "complete") {
    return {
      color: "green",
    };
  } else if (status === "confirm") {
    return {
      color: "red",
    };
  } else if (status === "sign") {
    return {
      color: "skyblue",
    };
  } else if (status === "added") {
    return {
      color: "green",
    };
  } else if (status === "removed") {
    return {
      color: "red",
    };
  } else {
    return {
      color: "gray",
    };
  }
};

interface AppContext {
  tabs: string;
  setTabs: (payload: string) => void;
  newUserCreated: boolean;
  setNewUserCreated: (payload: boolean) => void;
}

const File = () => {
  const [inputClick, setInputClick] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [records, setRecords] = useState(false);
  const [filterAll, setFilterAll] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const { tabs, setTabs, newUserCreated } = useAppContext() as AppContext;
  const [isActionOpen, setIsActionOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(""); // Initialize with a default filter value
  const [selectedDate, setSelectedDate] = useState(false);
  const [timeFilter, setTimeFilter] = useState<any>([null, null]);
  const [actualUserId, setActualUserId] = useState<number | null>(null);
  const [startDate, setstartDate] = useState<null | string>(null);
  const [endDate, setendDate] = useState<null | string>(null);
  const [pageNumber, setpageNumber] = useState<number>(1);

  //To set default on paperlink and also to set the active tob for the switch to define endponit
  const { customActiveTab } = useCustomActiveTabs();
  const activeTab = useSelector(selectActiveTabLabel);
  const dispatch = useDispatch();

  dispatch(setActiveTabLabel(customActiveTab));

  const toggleActionDropdown = () => {
    setIsActionOpen(!isActionOpen);
  };

  const toggleStatusDropdown = () => {
    setIsStatusOpen(!isStatusOpen);
  };

  //fetch from axios api
  const { users, error, data, loading, setIsDatePicked, isDatePicked } =
    useFetchUsers(
      searchValue,
      selectedFilter,
      selectedStatusFilter,
      startDate,
      endDate,
      pageNumber * 10 - 10
    );

  //paginated data
  const {
    currentPost,
    paginationButtons,
    prevButton,
    nextButton,
    viewAllButton,
  } = usePagination(
    1,
    searchValue,
    selectedFilter,
    selectedStatusFilter,
    users,
    filterAll,
    isDatePicked!
  );

  const totalPages = calculateTotalPages(users);

  const handleSearch = (value: string) => {
    // Remove extra spaces by using regex
    const cleanedValue = value.replace(/\s+/g, "_").trim();
    setSelectedFilter("");
    setSelectedStatusFilter("")
    setstartDate(null);
    setendDate(null);

    setSearchValue(cleanedValue);
    // setFilterAll(true);
  };

  // Page calculation
  function calculateTotalPages(users: UsersInfo[]) {
    let totalPages = 0;
    for (const user of users) {
      totalPages += user.pages;
    }
    return totalPages;
  }

  //for openSearch
  const handleInputClick = () => {
    setInputClick(!inputClick);
    setSearchValue("");
    setFilterAll(false);
  };

  // display of no records
  useEffect(() => {
    if (currentPost.length === 0 && inputClick) {
      setRecords(true);
    } else {
      setRecords(false);
    }
  }, [currentPost, inputClick]);

  useEffect(() => {
  }, [selectedUserId]);

  //tabs redirect
  const handleTabs = (userId: number, id: number) => {
    setSelectedUserId(userId);
    setActualUserId(id);
    setTabs("userDetails");
  };

  //handle modal of the date open
  const handleSelectedDate = () => {
    setSelectedDate(true);
  };

  //handle modal of the date close
  const handleCloseSelectedDate = () => {
    setSearchValue("");
    setSelectedFilter("");
    setSelectedStatusFilter("")
    if (inputClick) {
      setInputClick(!inputClick);
    }
    setSelectedDate(false);
  };
  //handling the date picker
  const getDateValuesFunc = (arg: {
    startDate?: string | null;
    dayDate?: string | null;
    endDate?: string | null;
  }) => {
    const { startDate, dayDate, endDate } = arg;
    if (dayDate) {
      setendDate(dayDate);
      setstartDate(dayDate);
    } else {
      setendDate(endDate ?? null);
      setstartDate(startDate ?? null);
    }
    startDate && endDate && handleCloseSelectedDate();
    setIsDatePicked(true);
  };
  //for status
  const handleActionFilter = (e: any) => {
    const selectedValue = e.target.getAttribute("data-value"); // Get the data-value attribute
    setSelectedFilter(selectedValue); // Update the selected filter state
    setSearchValue("");
    setSelectedStatusFilter("")
    setstartDate(null);
    setendDate(null);
    if (inputClick) {
      setInputClick(!inputClick);
    }

    //to see evry data concerning that field you use filter all which will reomve pagination
    setFilterAll(true);
    toggleActionDropdown();
  };

  const handleStatusFilter = (e: any) => {
    const selectedValue = e.target.getAttribute("data-value"); // Get the data-value attribute
    setSearchValue("");
    setSelectedFilter("");
    setstartDate(null);
    setendDate(null);
    if (inputClick) {
      setInputClick(!inputClick);
    }

    setSelectedStatusFilter(
      selectedValue === "added"? selectedValue :
      selectedValue === "removed"? selectedValue : 
      ""
    );
    //to see evry data concerning that field you use filter all which will reomve pagination
    setFilterAll(true);
    toggleStatusDropdown();
  };

  return (
    <div className="mb-[150px] border-radius-[0.9375rem] bg-white width-[65.75rem] h-auto overflow-hidden font-Poppins rounded-t-lg">
      {tabs === "tab" ? (
        <>
          {isStatusOpen && (
            <div className="relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
            <div className="absolute top-[8.25rem] right-[1rem] inline-block outline-none m-auto ease-in-out duration-1000 h-auto z-10 w-full cursor-pointer bg-gray-200 shadow-lg"
            style={{ zIndex: 1000,width: '6.25rem', height: '9.813rem' }}>
              <div
                onClick={handleStatusFilter}
                data-value="" // Assign a data attribute to store the value
                className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
              >
                All
              </div>
              <div
                onClick={handleStatusFilter}
                data-value="added" // Assign a data attribute to store the value
                className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
              >
                Added
              </div>
              <div
                onClick={handleStatusFilter}
                data-value="removed" // Assign a data attribute to store the value
                className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
              >
                Removed
              </div>
            </div>
            </div>
          )}

          {isActionOpen && (
            <div className="relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
            <div className="absolute top-[8.25rem] right-[8.5rem] inline-block outline-none m-auto ease-in-out duration-1000 h-auto z-10 w-full cursor-pointer bg-gray-200 shadow-lg"
            style={{ zIndex: 1000,width: '6.25rem', height: '13rem' }}>
              <div
                onClick={handleActionFilter}
                data-value="" // Assign a data attribute to store the value
                className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
              >
                All
              </div>
              <div
                onClick={handleActionFilter}
                data-value="complete" // Assign a data attribute to store the value
                className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
              >
                Complete
              </div>
              <div
                onClick={handleActionFilter}
                data-value="sign" // Assign a data attribute to store the value
                className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
              >
                Sign
              </div>
              <div
                onClick={handleActionFilter}
                data-value="confirm" // Assign a data attribute to store the value
                className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
              >
                Confirm
              </div>
            </div>
            </div>
          )}

          <div className="bg-secondaryColor flex items-center justify-between md:h-[4.1875rem]  px-6 py-2 rounded-t-lg">
            <div className="border-b-0 text-[#707070] !font-[500] leading-normal text-lg md:text-[1.2rem]">
              File Manager
            </div>
            <div className="border-b-0 flex gap-3">
              {/* SEARCH BAR */}
              {inputClick && (
                <div className="relative bottom-0 border-1 border-green-300 ">
                  <SearchBar
                    onSearch={(value) => handleSearch(value)} // Pass a callback to handle search value changes
                    inputClick={inputClick}
                    placeholder="Search users..." // Customize the placeholder if needed
                    buttonText="Search" // Customize the button text if needed
                  />
                </div>
              )}
              {!inputClick ? (
                <button
                  onClick={handleInputClick}
                  className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current bg-white rounded-full shadow-drop outline-none"
                >
                  <SearchGreen />
                </button>
              ) : (
                <button
                  onClick={handleInputClick}
                  className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current bg-slate-700 transition-all ease-in-out duration-700 hover:bg-red-500 rounded-full shadow-drop outline-none"
                >
                  <TfiClose color="white" />
                </button>
              )}
            </div>
          </div>

          {activeTab === "Paperlink" ? (
            <>
              {" "}
              {/* Desktop view */}
              <div className="hidden px-4 !py-0 md:block">
                {loading ? (
                  <Loader />
                ) : error ? (
                  <ErrorMessage message={error} />
                ) : (
                  <table className=" border-b w-full table-hover user-table" >
                    <thead>
                      <tr className="">
                        <th className=" p-5 px-4 text-left font-bold text-darkGray text-sm flex items-center">
                          <DateComponent getDateValue={getDateValuesFunc} />
                        </th>
                        <th className=" p-2 py-3  text-left font-bold text-darkGray text-sm">
                          PaperLink
                        </th>
                        <th className=" p-2 py-3 text-left  font-bold text-darkGray text-sm">
                          Upload By
                        </th>
                        <th className=" px-2 py-3 text-center font-bold text-darkGray text-sm ">
                          Pages
                          <span className=" px-2 p-1 justify-center gap-5  rounded-full bg-blue text-white text-xs ml-2">
                            {totalPages}
                          </span>
                        </th>
                        <th className="p-2 py-4 relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
                          <span className="flex justify-center items-center align-middle">
                            <div
                              className=" flex items-center cursor-pointer"
                              onClick={toggleActionDropdown}
                            >
                              <p>Action</p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#222529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M6 4v4"></path><path d="M6 12v8"></path><path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M12 4v10"></path><path d="M12 18v2"></path><path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M18 4v1"></path><path d="M18 9v11"></path></svg>
                            </div>
                          </span>
                        </th>
                        <th className=" p-2 py-4 relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
                          <span className="flex justify-center items-center align-middle">
                            <div
                              className=" flex items-center cursor-pointer"
                              onClick={toggleStatusDropdown}
                            >
                              <p>Status</p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#222529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M6 4v4"></path><path d="M6 12v8"></path><path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M12 4v10"></path><path d="M12 18v2"></path><path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M18 4v1"></path><path d="M18 9v11"></path></svg>
                            </div>
                          </span>
                        </th>
                      </tr>
                      
                    </thead>
                    {/* Desktop view */}
                    <tbody className="cursor-pointer">
                      {currentPost.map((user: any) => (
                        <tr
                          key={user.id}
                          className=" border-gray-200 hover:bg-gray-100"
                        >
                          <td className=" border-t py-4 p-2 text-left font-Poppins text-lightGray">
                            <div className="flex flex-col px-2">
                              <p className="text-lightGray font-Poppins text-left font-normal leading-normal px-2 text-sm">
                                {convertDateTime(user.createdAt)}
                              </p>
                            </div>
                          </td>
                          <td className=" border-t py-4 text-left text-lightGray  hover:text-green-500 font-Poppins text-sm font-normal">
                            <a
                              href={`${process.env.REACT_APP_BASE_URL}/pdf/${user.paperLink}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {user.fileName}
                            </a>
                          </td>
                          <td
                            onClick={() => handleTabs(user.userId, user.id)}
                            className=" border-t py-4 text-cente px-2 hover:text-red-500 underline  text-blue-500 font-Poppins text-sm font-normal"
                          >
                            {user?.userEmail}
                          </td>
                          <td className=" border-t py-4 text-center text-lightGray font-Poppins text-sm font-normal px-4">
                            {user.pages}
                          </td>
                          <td className=" border-t py-4 text-center text-lightGray font-Poppins text-sm font-normal  px-2">
                            <span
                              className="action"
                              style={makeStyle(user.fileAction)}
                            >
                              {/* we need to use Status which the API end point is not provided yet */}
                              {user.fileAction? user.fileAction.charAt(0).toUpperCase() + user.fileAction.slice(1).toLowerCase() : "Unpublished"}
                            </span>
                          </td>

                          <td className=" border-t py-4 text-center text-lightGray font-Poppins text-sm font-normal  px-2">
                            {user.isDeleted === false? (
                            <span
                              className="status"
                              style={makeStyle(user.isDeleted)}
                            >
                              Added
                            </span>) : (
                              <span
                              className="status"
                              style={makeStyle(user.isDeleted)}
                            >
                              Removed
                            </span>
                            )}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {!error && users.length === 0 && !loading && (
                  // when there are no records
                  <Empty activeTab={activeTab} searchValue={searchValue} />
                )}
              </div>




              
              {/* Mobile view */}
              <div className="md:hidden overflow-y-auto">
                {loading ? (
                  <Loader />
                ) : error ? (
                  <ErrorMessage message={error} />
                ) : (
                  <div className="md:hidden max-h-[400px] p-3 text-xs">
                    {currentPost.map((user: any) => (
                      <div
                        // onClick={() => handleMobileUserClick(user.id)} // Handle user row click
                        key={user.id}
                        className="flex gap-2 justify-between p-3 shadow-lg hover:shadow-2xl hover:bg-gray-200 rounded-md my-2 "
                      >
                        {selectedUserId == user.id && (
                          <div className="flex flex-col justify-start items-start align-middle flex-1 ">
                            <span className="text-black flex text-[12px]">
                              {convertDateTime(user.createdAt)}
                            </span>
                            <div className=" flex text-[12px] px-2  text-lightGray hover:text-green-500">
                              <a
                                href={`${process.env.REACT_APP_BASE_URL}/pdf/${user.paperLink}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {user.file.fileName}
                              </a>
                            </div>
                          </div>
                        )}

                        <div className="flex flex-col justify-start items-start align-middle flex-1 ">
                          <div className="text-black flex text-[12px]">
                            Pages: {user.pages}
                          </div>

                          <span
                            className="text-black text-[12px] font-extrabold"
                            style={makeStyle(user.fileAction)}
                          >
                            {user.fileAction}
                          </span>
                          <span
                            onClick={() => handleTabs(user.id, user.userId)}
                            className="active:text-red-500 underline text-[12px] text-blue-800"
                          >
                            {user?.user?.email}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* {!records ? (
                  ""
                ) : (
                  <div className="text-center py-4 w-full bg-green-300 text-2xl text-green-700">
                    Search complete. No record found
                  </div>
                )} */}
              </div>
              {data.total > 10 && (
                <PaginationComponent
                  total={data.total}
                  limit={10}
                  onChange={setpageNumber}
                  activePage={pageNumber}
                />
              )}
              {/* <div className="w-full bg-slate-100 flex justify-center mt-3 ">
                {prevButton} {paginationButtons} {viewAllButton} {nextButton}{" "}
              </div> */}
            </>
          ) : (
            <div className="h-[50vh] w-full mx-auto flex text-center font-extralight mt-3 flex-col">
              <h2>Sorry, No Records Found in {activeTab}</h2>
              <div className="h-[100%] w-full flex justify-center ">
                <Lottie loop={false} animationData={noRecords} />
              </div>
            </div>
          )}
        </>
      ) : 
      tabs === "userDetails" ? (
        <>
          <div>
            <FileTabRow
              selectedUserId={selectedUserId}
              users={users}
              actualUserId={actualUserId}
            />
          </div>
        </>
      ): (
        <>
          <div>
            <FileTabRow
              selectedUserId={selectedUserId}
              users={users}
              actualUserId={actualUserId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default File;
