// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
@keyframes giggle {
  0%, 100% {
    transform: translateY(0);
  }
  25%, 75% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
}

.animate-giggle-on-load {
  animation: giggle 2s ease-in-out; /* Adjust duration and timing function as needed */
}

`, "",{"version":3,"sources":["webpack://./src/components/PaperlinkShared/Header.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,gCAAgC,EAAE,kDAAkD;AACtF","sourcesContent":["/* styles.css */\n@keyframes giggle {\n  0%, 100% {\n    transform: translateY(0);\n  }\n  25%, 75% {\n    transform: translateY(-10px);\n  }\n  50% {\n    transform: translateY(10px);\n  }\n}\n\n.animate-giggle-on-load {\n  animation: giggle 2s ease-in-out; /* Adjust duration and timing function as needed */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
