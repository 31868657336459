import React, { useEffect, useState } from "react";
import AcctIcon from "./svg-icons/AcctIcon";
import zone from "../assests/zone.png";
import location from "../assests/location.png";
import "../assests/styles/tab.css";
import axios from "axios";
import EditIcon from "./svg-icons/EditIcon";
import { BsCheckCircleFill } from "react-icons/bs";
import { TfiFaceSad } from "react-icons/tfi";
import Loader from "../pages/Paperlink/resources/Loader";
import Arrow from "./svg-icons/Arrow";
import axiosInstance from "../utils/axiosInstance";
import { useAppContext } from "../AppConext";

interface Option {
  value: string;
  label: string;
}

interface AppContext {
  setTabs: (payload: string) => void;
}

const options: Option[] = [
  { value: "new_trial", label: "New Trial" },
  { value: "active", label: "active" },
  { value: "Pause", label: "Pause" },
  { value: "Suspend", label: "Suspend" },
  { value: "Cancel", label: "Cancel" },
  { value: "Disabled", label: "Disabled" },
  { value: "Delete", label: "Delete" },
];
// Sorting options alphabetically
options.sort((a, b) => a.label.localeCompare(b.label));

interface Tab1Props {
  selectedUser: any | undefined;
}

const Tab1: React.FC<Tab1Props> = ({ selectedUser }) => {
  const {setTabs } = useAppContext() as AppContext;
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [showProfilePictureModal, setShowProfilePictureModal] = useState(false);
  const [verify, setVerify] = useState(false);
  const [deleted, setDeleted] = useState(true);

  const [userDetail, setuserDetail] = useState(null);

  const [selectedOption, setSelectedOption] = useState<string>("");
  const [formData, setFormData] = useState({
    email: selectedUser?.email || selectedUser.user?.email || null,
    companyName: selectedUser?.companyName || null,
    firstName: selectedUser?.firstName || null,
    phone: selectedUser?.phone || null,
    timezone: selectedUser?.timezone,
    profilePicture: selectedUser?.profilePicture,
  });

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showTimeZoneTooltip, setShowTimeZoneTooltip] = useState(false);

  // Store the initial form data when editing is enabled
  const [initialFormData, setInitialFormData] = useState({ ...formData });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "Delete") setVerify(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, valueAsNumber } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleEditing = () => {
    if (!isEditing) {
      setFormData({ ...initialFormData }); // Reset formData to initial values
    }
    setIsEditing(!isEditing);
  };

  const handleClear = () => {
    setErrorMsg(false);
    setSuccess(false);
  };

  const handleViewProfilePicture = () => {
    setShowProfilePictureModal(true);
  };

  const handleCloseProfilePictureModal = () => {
    setShowProfilePictureModal(false);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const UpdateAccountUrl = `${process.env.REACT_APP_API_URL}/users/${selectedUser.id}`;

    axios
      .patch(UpdateAccountUrl, {
        email: formData?.email,
        companyName: formData?.companyName,
        firstName: formData.firstName,
        phone: formData?.phone,
        status: selectedOption,
        timezone: formData?.timezone,
        profilePicture: formData?.profilePicture,
      })
      .then((res) => {
        setSuccess(true);
        setErrorMsg(false);
        setInitialFormData({ ...formData }); // Reset initialFormData to saved data
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error updating data: ", err);
        if (err.response) {
          console.error("Response data: ", err.response.data);
          setSuccess(false);
          setErrorMsg(true);
          // setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //const handleCancel = () => {
  //  setShowCancelModal(true);
  // };

  const handleConfirmCancel = () => {
    setFormData({ ...initialFormData });
    setSelectedOption("");
    setIsEditing(false);
    setShowCancelModal(false);
    setTabs("tab");
    
  };

  const handleCancelModalClose = () => {
    setShowCancelModal(false);
  };

  const handleVerifyClose = () => {
    setVerify(false);
    setSelectedOption("active");
  };

  const handlDeleteAcc = () => {
    setVerify(false);

    const deleteUserUrl = `${process.env.REACT_APP_API_URL}/users/${selectedUser.id}`;

    axios
      .delete(deleteUserUrl)
      .then((res) => {
        setDeleted(true);
        window.location.reload();
        // Handle any additional logic after successful deletion
      })
      .catch((err) => {
        console.error("Error deleting user: ", err);
        // Handle error scenarios
      });
  };

  //https://dev-backend.paperlink.app/users/383
  //https://dev-backend.paperlink.app/users/383

  useEffect(() => {
    const getUserUrl = `${process.env.REACT_APP_API_URL}/users/${selectedUser.id}`;
    axiosInstance
      .get(getUserUrl)
      .then((res) => {
        setFormData(res.data);
        // Handle any additional logic after successful deletion
      })
      .catch((err) => {
        console.error("Error deleting user: ", err);
        // Handle error scenarios
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="h-auto overflow-hidden font-Poppins pb-[20px] ">
      {/**HERE THE loading */}
      {loading ? (
        <Loader />
      ) : (
        <div>
          {showCancelModal && (
            <div className="fixed inset-0 backdrop-blur-md bg-gray-800 bg-opacity-50 z-50"></div>
          )}
          <form
            onSubmit={handleSubmit}
            className="space-y-4  md:p-5 md:px-0 md:w-full relative"
          >
            <div className="inline-block md:flex md:justify-between mr-6 ml-6  w-full items-center text-center justify-center">
              <div className="mt-4 flex gap-5 w-full md:w-auto  items-center mb-5 justify-center">
                <label
                  htmlFor="select"
                  className="text-[#606060] font-Poppins text-20 font-normal"
                >
                  Status:
                </label>
                <div className="relative cursor-pointer">
                  <select
                    id="select"
                    name="select"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    className="bg-[#fbf5f5] text-[#FF7373] pr-[2.5rem] rounded-lg outline-none px-5 py-2  focus:ring-0 w-full font-[500] p-0 shadow-sm sm:text-sm appearance-none new-custom-select cursor-pointer"
                  >
                    <option value={selectedUser.status || selectedUser.user}>
                    {selectedUser.status === "new_trial"? "New Trial" : selectedUser.status}
                    </option>
                    {options?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 !text-[#707070]">
                    <Arrow />
                  </div>
                </div>

              </div>

              <div className="flex gap-2  items-center">
                <span>
                  {formData?.profilePicture ? (
                    <img
                      onClick={handleViewProfilePicture}
                      src={formData?.profilePicture}
                      alt="profile side"
                      className="inline-flex cursor-pointer items-center justify-center md:items-left h-20 w-20 md:w-12 md:h-12 flex-shrink-0 fill-current bg-grayG rounded-full shadow-drop mr-20 "
                    />
                  ) : (
                    <span
                      onClick={handleViewProfilePicture}
                      className="inline-flex cursor-pointer items-center justify-center md:items-left h-20 w-20 md:w-12 md:h-12 flex-shrink-0 fill-current bg-[#E9E9E9] rounded-full shadow-drop mr-20 "
                    >
                      <AcctIcon />
                    </span>
                  )}
                  {showProfilePictureModal && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                      <div className="fixed inset-0 bg-black opacity-40"></div>

                      <div className="bg-white p-8 w-96 rounded-lg text-center shadow-lg relative z-10">
                        <img
                          src={formData?.profilePicture}
                          alt="profile side"
                          className="mx-auto h-48 w-48 rounded-full bg-slate-100"
                        />
                        <button
                          onClick={handleCloseProfilePictureModal}
                          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 mt-4"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </span>
              </div>
            </div>

            <div className="block w-full md:flex md:flex-wrap md:mx-4 md:text-left  text-center ">
              <div className="md:w-1/2 px-4 mb-2">
                <label
                  htmlFor="email"
                  className="block py-5 pb-3 text-[#606060]"
                >
                  Email address:
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData?.email}
                  onChange={handleChange}
                  className="md:w-full px-2 py-2 border-b border-[#DADADA] bg-white focus:outline-none focus:border-[#adadad] focus:shadow-sm"
                  required
                />
              </div>
              <div className="md:w-1/2 px-4 mb-2">
                <label
                  htmlFor="companyName"
                  className="block py-5 pb-3 text-[#606060]"
                >
                  Business name:
                </label>
                <input
                  type="text"
                  name="companyName"
                  value={formData?.companyName}
                  onChange={handleChange}
                  className="md:w-full px-2 py-2 border-b border-[#DADADA] bg-white focus:outline-none focus:border-[#adadad] focus:shadow-sm"
                  required
                />
              </div>
              <div className="md:w-1/2 px-4 mb-4">
                <label
                  htmlFor="contactName"
                  className="block py-5 pb-3 text-[#606060]"
                >
                  Contact name:
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData?.firstName}
                  onChange={handleChange}
                  className="md:w-full px-2 py-2 border-b border-[#DADADA] bg-white focus:outline-none focus:border-[#adadad] focus:shadow-sm"
                  required
                />
              </div>
              <div className="md:w-1/2 px-4 mb-4">
                <label
                  htmlFor="contactNumber"
                  className="block py-5 pb-3 text-[#606060]"
                >
                  Contact number:
                </label>
                <input
                  type="number"
                  name="phone"
                  value={formData?.phone}
                  onChange={handleChange}
                  className="md:w-full px-2 py-2 border-b border-[#DADADA] bg-white focus:outline-none focus:border-[#adadad] focus:shadow-sm"
                  required
                />
              </div>
              <div className="mt-3 w-full flex justify-between items-center">
                <div className=" md:w-1/2 px-4 mb-6 flex items-center space-x-2">
                  <img
                    src={location}
                    alt="google map location"
                    className="mr-2"
                  />
                  <span className="text-[#606060]">Google maps location</span>
                </div>
                <div
                  className=" md:w-1/2 px-4 mb-6 flex items-center space-x-2"
                  onClick={() => setShowTimeZoneTooltip(!showTimeZoneTooltip)}
                >
                  <img src={zone} alt="zone-location" className="mr-2" />
                  <span className="text-[#606060]">Time Zone Database</span>
                </div>
              </div>
              {showTimeZoneTooltip && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div className="bg-white p-6 w-96 rounded-lg text-center shadow-lg">
                    <p className="text-gray-700 text-lg font-semibold mb-4">
                      Time Zone:
                    </p>
                    <p className="text-gray-700">
                      {formData?.timezone ? (
                        <p className="text-gray-700">{formData.timezone}</p>
                      ) : (
                        <p className="text-red-500">
                          Sorry, there is no timezone in the database
                        </p>
                      )}
                    </p>
                    <div className="mt-6">
                      <button
                        onClick={() => setShowTimeZoneTooltip(false)}
                        className="bg-red-500 text-white px-4 py-2 rounded-md mr-4 hover:bg-blue-600"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* copy and paste to remainig div */}
            
            {errorMsg && (
              <div className="fixed inset-0 flex items-center justify-center z-50 animate-fade-in ">
                <div className="bg-black opacity-70 inset-0 absolute h-[110vh] -top-5" />

                <div className="bg-white p-5 rounded-lg text-center shadow-lg relative z-10">
                  <div className="w-[50%] mx-auto flex justify-center items-center mb-2">
                    <TfiFaceSad color="red" size={50} />
                  </div>
                  <p className="text-red-500 flex w-[70%] mx-auto items-center justify-center">
                    Ops something went wrong, check your inputs and try again!
                  </p>
                  <button
                    onClick={handleClear}
                    className="bg-red-300 text-white px-4 py-2 rounded-md hover:bg-red-500 mt-4"
                  >
                    clear
                  </button>
                </div>
              </div>
            )}
            <div className="flex !mt-20 md:gap-10 gap-5 md:px-7  md:pb-5 flex-col md:flex-row items-center md:items-start ">
              {!isEditing ? (
                <>
                  <button
                    typeof="submit"
                    className={`Tab outline-none transition-all font-[500] duration-100 ease-in hover:bg-[#77C360] active:bg-[#77C360] ${
                      loading && "bg-slate-500"
                    } `}
                  >
                    {loading ? "Loading..." : "Save"}
                  </button>
                  <button
                    onClick={handleConfirmCancel}
                    className="btnT transition-all duration-100 ease-in font-[500] outline-none hover:border-[#FF7373]"
                  >
                    Cancel
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
          </form>

          {/**The clear modal */}
          {showCancelModal && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-white p-8 w-96 rounded-lg text-center shadow-lg">
                <p className="text-red-500 text-lg font-semibold mb-4">
                  Are you sure you want to cancel input?
                </p>
                <div>
                  <p>
                    <em>This will clear all what you editted.</em>
                  </p>
                </div>
                <div className="mt-6">
                  <button
                    onClick={handleConfirmCancel}
                    className="bg-red-500 text-white px-4 py-2 rounded-md mr-4 hover:bg-red-600"
                  >
                    Yes
                  </button>
                  <button
                    onClick={handleCancelModalClose}
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}

          {verify && selectedOption === "Delete" && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="bg-black opacity-70 inset-0 absolute h-[110vh] -top-5" />
              <div className="bg-white p-8 w-96 rounded-lg text-center shadow-lg relative">
                <p className="mb-4">
                  Deleting <span className="text-red-500">{formData?.email}</span> will remove their account, the registered business associated with them, and all related data from the database. This action is irreversible. Are you sure you want to proceed ?
                </p>

                <div className="mt-6">
                  <button
                    onClick={handlDeleteAcc}
                    className="bg-red-500 text-white px-4 py-2 rounded-md mr-4 hover:bg-red-600"
                  >
                    Yes Delete
                  </button>
                  <button
                    onClick={handleVerifyClose}
                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Tab1;
