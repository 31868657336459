// src/axiosConfig.js
import axios from "axios";
import { redirect } from "react-router-dom";


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API URL
});

axiosInstance.interceptors.request.use((config) => {

  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.accessToken = token;

    const tokenData = JSON.parse(atob(token.split(".")[1]));
    const tokenExpiration = tokenData.exp * 1000; // Convert to milliseconds

    if (Date.now() >= tokenExpiration) {
      // Token has expired, perform logout and redirect
      localStorage.removeItem("token");
      redirect("/login");
    }
  } else {
    redirect("/login"); // Redirect to the login page
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 2001) {
      // After successful login
      redirect("/paperLink");
    }

    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.log("Authentication error:", error.response.status);
      // You can handle unauthorized (401) errors here if needed.
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;