import React from "react";
import logo from "../../assests/paperlink/logo.svg";
import { useState, useEffect } from "react";
import { DASHBOARD_SIDEBAR_LINKS } from "../../constants/navigation";
import { Link, useLocation, useNavigate} from "react-router-dom";
import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedLinkKey } from "../../store/selectedLinkKeySlice"; // Import your slice
import { selectSelectedLinkKey } from "../../store/selectedLinkKeySlice"; // Import the selector
import { useAppContext } from "../../AppConext";

const linkClass =
  "flex items-center gap-2 mb-1 shadow-[#77C360] font-light px-3 py-3 hover:no-underline rounded-md text-sm";

type LinkType = {
  key: string;
  path: string;
  icon: React.ReactNode;
  label: string;
};

type SidebarProps = {};

type SidebarLinkProps = {
  link: LinkType;
  pathname: string;
  selectedLinkKey: string;
  handleLinkClick: (key: string) => void;
};

interface AppContext {
  setTabs: (payload: string) => void;
  newUserCreated: boolean;
  setNewUserCreated: (payload: boolean) => void;
  setActiveTab: (payload: string) => void;
}

export default function Sidebar(props: SidebarProps) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // Access selectedLinkKey from the Redux store using the selector
  const selectedLinkKey = useSelector(selectSelectedLinkKey);

  const handleLinkClick = (key: string) => {
    dispatch(setSelectedLinkKey(key));
    // You can perform other actions related to the selected link here
  };

  return (
    <div className="hidden z-30 py-4 md:width-[17.4375rem] md:bg-white md:shadow-md md:px-6 md:font-poppins md:flex md:flex-col">
      <div className="flex items-center justify-center gap-2 px-1 mb-5">
        <img src={logo} alt="site logo" className="" />
      </div>

      <div className="flex flex-col gap-1 mt-4">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          <SidebarLink
            key={link.key}
            link={link}
            pathname={pathname}
            selectedLinkKey={selectedLinkKey}
            handleLinkClick={handleLinkClick}          
          />
        ))}
      </div>
    </div>
  );
}

function SidebarLink(props: SidebarLinkProps) {
  const { link, pathname, selectedLinkKey, handleLinkClick } = props;
  const isActive = pathname === link.path;
  const { setTabs, setNewUserCreated, setActiveTab , newUserCreated} = useAppContext() as AppContext;

  return (
    <Link
      to={link.path}
      onClick={() => {
        handleLinkClick(link.key);
        setTabs("tab");
        setNewUserCreated(!newUserCreated);
        setActiveTab("tab1");
      }}
      className={`
      text-[#707070]
        ${isActive ? "bg-[#77C360] text-white active-tab" : ""}
        ${linkClass}
      `}
    >
      <div className="flex items-center gap-[0.65rem] p-[0.45rem] py-1">
        <div className="text-xl text-red min-w-[30px] tab-icon">
          {link.icon}
        </div>
        <span className="text-sm font-[500] ">{link.label}</span>
      </div>
    </Link>
  );
}
