import React, { useState } from "react";
import Tab1 from "../components/Tab1";
import Tab2 from "../components/Tab2";
import Tabs from "../components/Tabs";

interface TabrowProps {
  user: any; // Replace 'YourUserType' with the actual type of your users
}

const Tabrow: React.FC<TabrowProps> = ({ user }) => {
  const selectedUser = user;
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className="!border-radius-[0.9375rem] bg-white w-1852  h-auto overflow-hidden font-Poppins">
      <div className="bg-secondaryColor flex flex-col justify-between height-[5.3125rem] px-4 py-2 rounded-t-lg pb-0">
        <div className="hidden md:inline border-b-0 px-1 pt-2 text-[#707070] !font-[500] leading-normal text-lg md:text-[1.2rem]">
          Accounts
        </div>
        <div className="flex w-full justify-end items-baseline">
          <div className="flex justify-between  items-baseline w-[80%] mx-auto end-0 h-full pt-0">
            <div className="flex justify-between gap-4 md:gap-5 border-b w-full text-sm md:text-[15px] ">
              <button
                className={`flex ml-10 pb-1 ${
                  activeTab === "tab1"
                    ? "border-b-4 rounded overflow-hidden border-[#222529] font-extrabold "
                    : "text-gray-500"
                }`}
                onClick={() => handleTabClick("tab1")}
              >
                <p className="md:inline hidden mr-2">Account </p> Profile
              </button>
              <button
                className={`mr-12 pb-1 ${
                  activeTab === "tab2"
                    ? "border-b-4 rounded overflow-hidden border-[#222529] "
                    : "text-gray-500"
                }`}
                onClick={() => handleTabClick("tab2")}
              >
                Subscription
              </button>
              <button
                className={`mr-9 pb-1 ${
                  activeTab === "tab3"
                    ? "border-b-4 rounded overflow-hidden border-[#222529] "
                    : "text-gray-500"
                }`}
                onClick={() => handleTabClick("tab3")}
              >
                Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Tabs for mobile view */}
      <div className="md:hidden  w-full ">
        <div className="flex justify-between  w-full h-auto"></div>
      </div>

      {activeTab === "tab1" && (
        <div className="">
          {/* Content for Tab 1 */}
          <Tab1 selectedUser={selectedUser} />
        </div>
      )}
      {activeTab === "tab2" && (
        <div>
          {/* Content for Tab 2 */}
          {/* Your tab 2 content here */}
          <Tab2 selectedUser={selectedUser} />
        </div>
      )}

      {activeTab === "tab3" && (
        <div>
          {/* Content for Tab 3 */}
          {/* Your tab 3 content here */}
          <Tabs selectedUser={selectedUser} />
        </div>
      )}
    </div>
  );
};

export default Tabrow;
