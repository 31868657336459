import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import MobileSidebar from "./MobileSidebar";
import MobileHeader from "./MobileHeader";

export default function Layout() {
  return (
    <div className="bg-mainColor h-screen w-screen overflow-hidden flex flex-row font-Poppins">
      <Sidebar />
      <MobileSidebar />
      <div className="flex flex-col flex-1">
        <Header />
        <MobileHeader />
        {/* <MobileToplinks/> */}
        <div className="flex-1 p-6 min-h-0 overflow-auto mt-[20px] max-md:mt-[50px]">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
