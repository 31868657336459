import React, { useEffect, useState } from "react";
import SearchGreen from "../../components/svg-icons/SearchGreen";
import { TfiClose } from "react-icons/tfi";
import Loader from "./resources/Loader";
import ErrorMessage from "./resources/ErrorMessage";
import SearchBar from "./resources/SearchBar";
import useLedger from "../../hooks/APIrequest/useLedger";
import useUserPagination from "../../hooks/Paginations/useUserPagination";
import convertDateTime from "./resources/DateConverter";
import Arrow from "../../components/svg-icons/Arrow";
import UserTabrow from "../Paperlink/resources/UserTabeow/UserTabrow";
// import { Text, Pagination } from "@mantine/core";
import Pagination from "react-js-pagination";
import { UsersInfo } from "./resources/UsersInfo";

//no record icon
import noRecords from "../../assests/noRecords.json";
import Lottie from "lottie-react";

//redux
import { useSelector, useDispatch } from "react-redux";
import { selectActiveTabLabel, setActiveTabLabel } from "../../store/tab-slice";

//hook for seting tabs to default paperLink
import useCustomActiveTabs from "../../hooks/Others/useCustomActiveTabs";

import { Ledger } from "./resources/Ledger";
import "./User.css";
import Empty from "./resources/Empty";
import Tabrow from "../../utils/Tabrow";
import DateComponent from "../../components/DateComponent";
import PaginationComponent from "../../components/paginationComponent";
import { useAppContext } from "../../AppConext";


const makeStyle = (action: string) => {
  if (action === "complete") {
    return {
      color: "green",
    };
  } else if (action === "sign") {
    return {
      color: "skyblue",
    };
  } else if (action === "confirm") {
    return {
      color: "blue",
    };
  } else if (action === "Delete") {
    return {
      color: "red",
    };
  } else {
    return {
      color: "gray",
    };
  }
};

interface AppContext {
  tabs: string;
  setTabs: (payload: string) => void;
}

const User = () => {
  const [inputClick, setInputClick] = useState(false);
  const [filterAll, setFilterAll] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [records, setRecords] = useState(false);
  const {tabs, setTabs} = useAppContext() as AppContext;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(""); // Initialize with a default filter value
  const [selectedDate, setSelectedDate] = useState(false);
  const [selectedUser, setselectedUser] = useState(null);
  const [startDate, setstartDate] = useState<null | string>(null);
  const [endDate, setendDate] = useState<null | string>(null);
  const [pageNumber, setpageNumber] = useState<number>(1);
  
  

  //To set default on paperlink and also to set the active tob for the switch to define endponit
  const { customActiveTab } = useCustomActiveTabs();
  const activeTab = useSelector(selectActiveTabLabel);
  const dispatch = useDispatch();
  dispatch(setActiveTabLabel(customActiveTab));

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const { loading, users, error, data, setIsDatePicked } = useLedger(
    searchValue,
    selectedFilter,
    startDate,
    endDate,
    pageNumber * 10 - 10
  );

  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const totalPages = calculateTotalPages(users);

  const handleSearch = (value: string) => {
    // Remove extra spaces by using regex
    const cleanedValue = value.replace(/\s+/g, "_").trim();
    setSelectedFilter("")
    setstartDate(null);
    setendDate(null);
    setSearchValue(cleanedValue);
  };

  // Page calculation
  function calculateTotalPages(users: Ledger[]) {
    let totalPages = 0;
    for (let user of users) {
      totalPages += 1;
    }
    
    return totalPages;
  }
  // useEffect to fetch info from the Postman URL
  const handleInputClick = () => {
    setInputClick(!inputClick);
    setSearchValue("");

    setFilterAll(false);
  };

  const handleStatusFilter = (e: any) => {
    const selectedValue = e.target.getAttribute("data-value"); // Get the data-value attribute
    setSelectedFilter(selectedValue); // Update the selected filter state
    setSearchValue(""); // Clear the search value
    if (inputClick) {
      setInputClick(!inputClick);
    }
    //to see evry data concerning that field you use filter all which will reomve pagination
    setstartDate(null);
    setendDate(null);
    setFilterAll(true);
    toggleDropdown()
  };

  //because length start fron 0
  const recordFound = users.length > 0;

  // Function to handle clicking on a user row and display additional information
  const handleMobileUserClick = (fileOwner: number) => {
    setSelectedUserId(selectedUserId === fileOwner ? null : fileOwner); // Toggle selected user
  };

  //tabs redirect
  const handleTabs = (user: any,) => {
    setselectedUser(user.fileOwner);
    setTabs("userDetails");
  };

  // data filteration logic will be here
  const handleCloseSelectedDate = () => {
    setSearchValue("");
    setSelectedFilter("")
    if (inputClick) {
      setInputClick(!inputClick);
    }
    setSelectedDate(false);
  };

  const getDateValuesFunc = (arg: {
    startDate?: string | null;
    dayDate?: string | null;
    endDate?: string | null;
  }) => {
    const { startDate, dayDate, endDate } = arg;
    if (dayDate) {
      setendDate(dayDate);
      setstartDate(dayDate);
    } else {
      setendDate(endDate ?? null);
      setstartDate(startDate ?? null);
    }
    startDate && endDate && handleCloseSelectedDate();
    setIsDatePicked(true);
  };

  return (
    <>
      {isOpen && (
        <div className="relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
        <div className="absolute top-[8.25rem] right-[2rem] inline-block outline-none m-auto ease-in-out duration-1000 h-auto z-10 w-full cursor-pointer bg-gray-200 shadow-lg"
        style={{ zIndex: 1000,width: '6.25rem', height: '13rem' }}>
          <div
            onClick={handleStatusFilter}
            data-value="" // Assign a data attribute to store the value
            className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
          >
            All
          </div>
          <div
            onClick={handleStatusFilter}
            data-value="complete" // Assign a data attribute to store the value
            className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
          >
            Complete
          </div>
          <div
            onClick={handleStatusFilter}
            data-value="sign" // Assign a data attribute to store the value
            className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
          >
            Sign
          </div>
          <div
            onClick={handleStatusFilter}
            data-value="confirm" // Assign a data attribute to store the value
            className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
          >
            Confirm
          </div>
        </div>
        </div>
      )}
    <div className="mb-20">
      {tabs === "tab" ? (
        <div>
          <div className=" md:mb-0 border-radius-[0.9375rem] bg-white width-[65.75rem] h-auto overflow-hidden font-Poppins rounded-t-lg">
            <div className="bg-secondaryColor flex items-center justify-between md:h-[4.1875rem] rounded-t-lg px-6 py-2">
              <div className="border-b-0 text-[#707070] !font-[500] leading-normal text-lg md:text-[1.2rem]">
                User Log
              </div>
              <div className="border-b-0 flex gap-3 items-center">
                {!inputClick ? (
                  ""
                ) : (
                  <div className="relative md:bottom-0 md:border-1 border-green-300 ">
                    <SearchBar
                      onSearch={(value) => handleSearch(value)} // Pass a callback to handle search value changes
                      inputClick={inputClick}
                      placeholder="Search users..." // Customize the placeholder if needed
                      buttonText="Search" // Customize the button text if needed
                    />
                  </div>
                )}
                {!inputClick ? (
                  <button
                    onClick={handleInputClick}
                    className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current bg-white rounded-full shadow-drop outline-none"
                  >
                    <SearchGreen />
                  </button>
                ) : (
                  <button
                    onClick={handleInputClick}
                    className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current bg-slate-700 transition-all ease-in-out duration-700 hover:bg-red-500 rounded-full shadow-drop outline-none"
                  >
                    <TfiClose color="white" />
                  </button>
                )}
              </div>
            </div>
            {/* Sourrounding every body (both mobile and desktop) */}
            {activeTab === "Paperlink" ? (
              <>
                <div className="md:overflow-x-auto px-4 !py-0">
                  {/* Table for wide screens */}
                  {loading ? (
                    <div className="hidden md:inline">
                      <Loader />
                    </div>
                  ) : (
                    <div className="hidden md:block">
                      
                        <table className="md:w-full !p-10 md:table-hover md:user-table">
                          <thead className="">
                              <tr className="">
                                <th className=" py-5  px-3 text-left font-bold text-darkGray text-sm flex items-center">
                                  <DateComponent
                                    dates={{
                                      startDate: startDate,
                                      endDate: endDate,
                                    }}
                                    getDateValue={getDateValuesFunc}
                                  />
                                </th>
                                <th className="border-b p-2 text-left font-bold text-darkGray text-sm">
                                  Free User
                                </th>
                                <th className="border-b p-2 text-left font-bold text-darkGray text-sm">
                                  Account Email
                                </th>
                                <th className="border-b p-2 text-left font-bold text-darkGray text-sm">
                                  Paperlink
                                </th>
                                <th className="border-b px-4 py-3 text-center font-medium text-darkGray text-sm">
                                  Pages
                                  <span className="px-2 p-1 justify-center gap-5 rounded-full bg-blue text-white text-xs ml-2">
                                    {totalPages}
                                  </span>
                                </th>

                                <th className="p-2 py-4 relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
                                  <span className="flex justify-center items-center align-middle">
                                    <div
                                      className="flex items-center cursor-pointer"
                                      onClick={toggleDropdown}
                                    >
                                      <p>Action</p>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#222529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M6 4v4"></path><path d="M6 12v8"></path><path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M12 4v10"></path><path d="M12 18v2"></path><path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M18 4v1"></path><path d="M18 9v11"></path></svg>
                                    </div>
                                  </span>
                                </th>
                              </tr>
                            </thead>
                          
                          {recordFound && (
                          <tbody className="cursor-pointer  ">
                            {users.map((user: any) => (
                              user.file.isDeleted === false && (
                              <>
                              <tr
                                key={user.fileOwner}
                                className="border-gray-200 hover:bg-gray-100"
                              >
                                <td className="border-t py-4 p-1 text-left font-Poppins text-lightGray">
                                  {convertDateTime(user.updatedAt)}
                                </td>
                                <td className="border-t py-4 p-2 text-left text-lightGray font-Poppins text-sm font-normal">
                                  {user.guestName ? (
                                    user.guestName
                                  ) : (
                                    <p className="text-gray-300 cursor-not-allowed">
                                      No Guest Name
                                    </p>
                                  )}
                                </td>

                                <td
                                  onClick={() => handleTabs(user)}
                                  className=" border-t py-4 p-2 text-blue-800 active:text-green-400 text-left hover:text-red-500 font-Poppins text-sm font-normal"
                                >
                                  {user.fileOwnerEmail}
                                </td>
                                <td className="border-t py-4 p-2 text-left text-lightGray hover:text-green-500 font-Poppins text-sm font-normal px-3">
                                  <a
                                    href={`${process.env.REACT_APP_BASE_URL}/pdf/${user.file.paperLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {user.fileName}
                                  </a>
                                </td>
                                <td className="border-t py-4 p-2 text-center text-lightGray font-Poppins text-sm font-normal px-2">
                                  {user.file.pages}
                                </td>
                                <td className="border-t py-4 text-center text-lightGray font-Poppins text-sm font-normal px-3">
                                  <span
                                    className="status capitalize"
                                    style={makeStyle(user.action)}
                                  >
                                    {user.action}
                                  </span>
                                </td>
                              </tr>
                              </>
                            )))}
                          </tbody>
                      )}
                      </table>
                      {/* the correct logic to show no users please make this a hook next time to avoid copy and paste */}
                      {!error && users.length === 0 && !loading && (
                        // when there are no records
                        <Empty
                          activeTab={activeTab}
                          searchValue={searchValue}
                        />
                      )}
                      {error && <ErrorMessage message={error} />}
                    </div>
                  )}

                  {/* Mobile view Display mb-[200px] */}

                  {loading ? (
                    <div className="md:hidden">
                      <Loader />
                    </div>
                  ) : (
                    // i also removed padding bottom from here
                    <div className="md:hidden max-h-[400px] text-xs overflow-y-auto">
                      {recordFound &&
                        users.map((user: any) => (
                          <div
                            onClick={() => handleMobileUserClick(user.fileOwner)} // Handle user row click
                            key={user.id}
                            className="flex gap-2 justify-between p-3 shadow-lg hover:shadow-2xl hover:bg-gray-200 rounded-md my-2"
                          >
                            {selectedUserId === user.id && (
                              <div className="flex flex-col justify-start items-start align-middle flex-1">
                                <span className="text-black flex text-[12px]">
                                  {convertDateTime(user.updatedAt)}
                                </span>
                                <div
                                  // onClick={() => handleTabs(user.id)}
                                  className="text-blue-800 underline flex text-[12px] p-2 "
                                >
                                  {user.fileOwnerEmail}
                                </div>
                              </div>
                            )}
                            <div className="flex flex-col justify-start items-start align-middle flex-1">
                              <span className="text-black flex text-[12px]]">
                                {user.file.paperLink}
                              </span>
                              <div className="text-black text-center flex text-[12px]">
                                Pages: {user.file.pages}
                              </div>
                              <div className="text-black flex text-[12px]">
                                User: {user.guestName}
                              </div>
                              <span
                                className="text-black text-[12px] font-extrabold"
                                style={makeStyle(user.action)}
                              >
                                {user.action}
                              </span>
                            </div>
                          </div>
                        ))}
                      {/* if there are no search */}
                      {records && (
                        <div className="h-[50vh] w-full mx-auto flex text-center font-extralight mt-3 flex-col">
                          <h2>
                            Search complete! Sorry, No Records Found for "
                            {searchValue}"
                          </h2>
                          <div className="h-[100%] w-full flex justify-center ">
                            <Lottie animationData={noRecords} />
                          </div>
                        </div>
                      )}
                      {!error && users.length === 0 && !loading && (
                       // when there are no records
                      <Empty activeTab={activeTab} searchValue={searchValue} />
                      )}
                      {error && <ErrorMessage message={error} />}
                    </div>
                  )}
                </div>
                

                {data.total > 10 && (
                  <PaginationComponent
                    total={data.total}
                    limit={10}
                    onChange={setpageNumber}
                    activePage={pageNumber}
                  />
                )}
              </>
            ) : (
              <Empty activeTab={activeTab} searchValue={undefined} />
            )}
          </div>
        </div>
      ) :
      tabs === "userDetails" ? (
        <div>
          <UserTabrow selectedUserId={selectedUser}/>
        </div>
      ): (
        <>
          <div>
          <UserTabrow selectedUserId={selectedUser}/>
          </div>
        </>
      )}
    </div>
    </>
  );
};
export default User;
