import { useEffect, useState, useCallback } from "react";
import { Infor } from "../../pages/Paperlink/resources/Infor";
import axios from "axios";
import { Console } from "console";

interface DataValue {
  data?: Object;
  limit?: number;
  skip?: number;
  total: number;
}

const useAccApi = (
  selectedFilter: string,
  searchValue: string,
  startDate?: string | null ,
  endDate?: string | null,
  page?: number,
  newUserCreated?: boolean
) => {
  const [users, setUsers] = useState<Infor[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDatePicked, setIsDatePicked] = useState<boolean | null>(null);
  const [data, setData] = useState<DataValue>({total:0});


  const datefilter =
    startDate 
      ? `&createdAt[$gte]=${startDate || ""}&createdAt[$lte]=${endDate || ""}`
      : "";

  const paidUserUrl =
    `${process.env.REACT_APP_API_URL}/users?$sort[createdAt]=-1&role=paid_user&$or[0][companyName][$like]=${selectedFilter}%&$or[1][email][$like]=${selectedFilter}%&$or[2][businessPage][$like]=${selectedFilter}%&$or[3][status][$like]=${selectedFilter}%${datefilter}&$skip=${page}&$limit=10`;

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(paidUserUrl);
        setData(response.data);
        setUsers(response.data.data);
        setError(null);
      } catch (err) {
        setError("There was an error fetching data.");
        console.log("there was an issue: ", err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedFilter, searchValue, page, datefilter, newUserCreated]);

  return {
    loading,
    users,
    error,
    // selectedFilter,
    searchValue,
    isDatePicked,
    setIsDatePicked,
    data
  };
};

export default useAccApi;
