// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg {
  /* background-color: rgb(58, 180, 9);*/
  background: #77b550;
}

.col {
  color: var(--txt-green, #5FA348);

}

.input-error {
  border: 2px solid rgb(255, 203, 203);
}
/* Change the font of the input placeholder */
input::placeholder {
  font-family: "Poppins", sans-serif; /* Set your desired font-family */
  font-size:16px;
  color: rgba(0, 0, 0, 0.30);

  /* Set your desired placeholder text color */
}

.input-nutral {
  border: 2px solid rgb(233, 228, 228);
}
/* styles.css */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;;AAElC;;AAEA;EACE,oCAAoC;AACtC;AACA,6CAA6C;AAC7C;EACE,kCAAkC,EAAE,iCAAiC;EACrE,cAAc;EACd,0BAA0B;;EAE1B,4CAA4C;AAC9C;;AAEA;EACE,oCAAoC;AACtC;AACA,eAAe;AACf;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".bg {\n  /* background-color: rgb(58, 180, 9);*/\n  background: #77b550;\n}\n\n.col {\n  color: var(--txt-green, #5FA348);\n\n}\n\n.input-error {\n  border: 2px solid rgb(255, 203, 203);\n}\n/* Change the font of the input placeholder */\ninput::placeholder {\n  font-family: \"Poppins\", sans-serif; /* Set your desired font-family */\n  font-size:16px;\n  color: rgba(0, 0, 0, 0.30);\n\n  /* Set your desired placeholder text color */\n}\n\n.input-nutral {\n  border: 2px solid rgb(233, 228, 228);\n}\n/* styles.css */\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.animate-fade-in {\n  animation: fadeIn 1s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
