import React, { FC } from "react";
//import TimeLinkRoutes from "./Routing/TimeLinkRoutes";
import MainRoutes from "./Routing/Routes";
import { useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";


//import SignInRoutes from "./Routing/SignInRoutes";

interface RootState {
  auth: {
    isLoggedIn: boolean;
    // add other properties if needed
  };
  // add other reducers if needed
}

interface AppProps {
  // define props here if needed
}

const App: FC<AppProps> = () => {
  const queryClient = new QueryClient();

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <MainRoutes />
        {/* <SignInRoutes />*/}
        {/* <TimeLinkRoutes/>*/}
      </div>

    </QueryClientProvider>


  );
}

export default App;
