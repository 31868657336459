import React, { useEffect, useState } from "react";
import SearchGreen from "../../components/svg-icons/SearchGreen";
import { TfiClose } from "react-icons/tfi";
import { UsersInfo } from "./resources/UsersInfo";
import SearchBar from "./resources/SearchBar";
import Loader from "./resources/Loader";
import ErrorMessage from "./resources/ErrorMessage";
import convertDateTime from "./resources/DateConverter";
import useTeamsPagination from "../../hooks/Paginations/useTeamsPaginations";
import Arrow from "../../components/svg-icons/Arrow";
import useTeamsApi from "../../hooks/APIrequest/useTeamsApi";
import { UserData } from "./resources/TeamInfor";

//no record icon
import noRecords from "../../assests/noRecords.json";
import Lottie from "lottie-react";

//redux
import { useSelector, useDispatch } from "react-redux";
import { selectActiveTabLabel, setActiveTabLabel } from "../../store/tab-slice";

//hook for seting tabs to default paperLink
import useCustomActiveTabs from "../../hooks/Others/useCustomActiveTabs";
import Empty from "./resources/Empty";
import PaginationComponent from "../../components/paginationComponent";
import { useAppContext } from "../../AppConext";
import TeamTabRow from "./resources/TeamTabrow/TeamTabRow";

const makeStyle = (status: string) => {
  if (status === "New Trial") {
    return {
      color: "purple",
    };
  } else if (status === "Removed") {
    return {
      color: "red",
    };
  } else if (status === "Active") {
    return {
      color: "green",
    };
  } else {
    return {
      color: "gray",
    };
  }
};

interface AppContext {
  tabs: string;
  setTabs: (payload: string) => void;
}

const Teams = () => {
  const [inputClick, setInputClick] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [records, setRecords] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setpageNumber] = useState<number>(1);
  const {tabs, setTabs} = useAppContext() as AppContext;
  const [selectedUser, setselectedUser] = useState<number | null>(null);
  const [actualUserId, setActualUserId] = useState<number | null>(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  //To set default on paperlink and also to set the active tob for the switch to define endponit
  const { customActiveTab } = useCustomActiveTabs();
  const activeTab = useSelector(selectActiveTabLabel);
  const dispatch = useDispatch();

  dispatch(setActiveTabLabel(customActiveTab));

  const { loading, data, users1, error } = useTeamsApi(
    searchValue,
    searchFilter
  );
  //filter users array where companyEmail!=teamMemberEmail
  const users = users1.filter((user) => user.companyEmail !== user.teamMemberEmail);
  const [filterAll, setFilterAll] = useState(false);
  const {
    currentPost,
    paginationButtons,
    nextButton,
    prevButton,
    viewAllButton,
  } = useTeamsPagination(
    1,
    searchFilter,
    searchValue,
    users,
    filterAll);

  // For Postman URL and calculating total pages
  const totalPages = calculateTotalPages(users);

  const handleTabs = (userId: number,user: any) => {
    setselectedUser(userId);
    setActualUserId(user.ownersId);
    setTabs("userDetails");
  };

  // useEffect to fetch info from the Postman URL
  const handleInputClick = () => {
    setInputClick(!inputClick);
    setSearchValue("");
    // setFilterAll(false);
  };

  const handleSearch = (value: string) => {
    // Remove extra spaces by using regex
    const cleanedValue = value.replace(/\s+/g, "_").trim();
    setSelectedStatusFilter("")

    setSearchValue(cleanedValue);
    // setFilterAll(true);
  };

  // Page calculation
  function calculateTotalPages(users: UserData[]) {
    let totalPages = 0;
    for (const user of users) {
      totalPages += user.user.totalPages;
    }
    return totalPages;
  }

  //const recordFound = currentPost.length > 0;

  const handleStatusFilter = (e: any) => {
    const searchFilter = e.target.getAttribute("data-value"); // Get the data-value attribute

    setSearchValue(""); // Update the selected filter state
    setSearchFilter(
      searchFilter === "active"? searchFilter :
      searchFilter === "pending"? searchFilter : 
      ""
    )
    
    toggleDropdown();

    // //to see evry data concerning that field you use filter all which will reomve pagination
    // setFilterAll(true);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setFilterAll(false);
  };

  // display of no records
  useEffect(() => {
    if (currentPost.length === 0 && inputClick) {
      setRecords(true);
    } else {
      setRecords(false);
    }
  }, [currentPost, inputClick]);

  return (
    
    <div>
      {isOpen && (
        <div className="relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
        <div className="absolute top-[8.25rem] right-[1rem] h-[50px] z-[50px] w-full inline-block outline-none m-auto ease-in-out duration-1000 cursor-pointer bg-gray-200 shadow-lg"
        style={{ zIndex: 1000,width: '6.25rem', height: '9.813rem' }}>
          <div
            onClick={handleStatusFilter}
            data-value="" // Assign a data attribute to store the value
            className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
          >
            All
          </div>
          <div
            onClick={handleStatusFilter}
            data-value="active" // Assign a data attribute to store the value
            className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
          >
            Active
          </div>
          <div
            onClick={handleStatusFilter}
            data-value="pending" // Assign a data attribute to store the value
            className="p-4 hover:bg-slate-600 ease-in-out duration-300 hover:text-white"
          >
            Pending
          </div>
        </div>
        </div>
      )}
      {tabs === "tab" ? (
    <div className="border-radius-[0.9375rem] bg-white mb-[150px] width-[65.75rem] h-auto  overflow-hidden font-Poppins rounded-t-lg">
      <div className="bg-secondaryColor flex items-center justify-between px-6 md:py-2 rounded-t-lg">
        <div className="border-b-0 text-[#707070] !font-[500] py-[0.6rem] leading-normal text-lg md:text-[1.2rem]">
          Teams
        </div>
        <div className="border-b-0 flex gap-3">
          {!inputClick ? (
            ""
          ) : (
            <div className="relative bottom-0 border-1 border-green-300 ">
              <SearchBar
                onSearch={(value) => handleSearch(value)} // Pass a callback to handle search value changes
                inputClick={inputClick}
                placeholder="Search users..." // Customize the placeholder if needed
                buttonText="Search" // Customize the button text if needed
              />
            </div>
          )}
          {!inputClick ? (
            <button
              onClick={handleInputClick}
              className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current bg-white rounded-full shadow-drop outline-none"
            >
              <SearchGreen />
            </button>
          ) : (
            <button
              onClick={handleInputClick}
              className="inline-flex items-center justify-center h-7 w-7 md:w-10 md:h-10 flex-shrink-0 fill-current bg-slate-700 transition-all ease-in-out duration-700 hover:bg-red-500 rounded-full shadow-drop outline-none"
            >
              <TfiClose color="white" />
            </button>
          )}
        </div>
      </div>

      {activeTab === "Paperlink" ? (
        <>
          {" "}
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="hidden md:inline">
                <table className="w-full p-10 table-hover user-table">
                  <thead className="">
                    <tr className="">
                      <th className="pl-5 p-5 py-6  text-left font-bold text-black text-sm">
                        Teams Email
                      </th>

                      <th className="border-b p-2 py-6 text-left font-bold text-black text-sm">
                        Account Email
                      </th>
                      <th className="border-b p-2 py-6  text-left font-bold text-black text-sm">
                        Bussiness Name
                      </th>
                      <th className="border-b px-4 py-6 text-left font-bold text-black text-sm ">
                        Created
                      </th>
                      <th className="p-2 py-4 relative justify-center  text-center font-bold text-darkGray text-sm  z-10">
                        <span className="flex justify-center items-center align-middle">
                          <div
                            className="flex items-center cursor-pointer"
                            onClick={toggleDropdown}
                          >
                            <p>Status</p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#222529" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M6 4v4"></path><path d="M6 12v8"></path><path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M12 4v10"></path><path d="M12 18v2"></path><path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path><path d="M18 4v1"></path><path d="M18 9v11"></path></svg>
                          </div>
                        </span>
                        
                      </th>
                    </tr>
                  </thead>

                  <tbody className=" cursor-pointer">
                    {currentPost.map((user) => (
                      user.teamMemberEmail!=user.companyEmail &&
                      <tr
                        key={user.id}
                        className=" border-gray-200 hover:bg-gray-100 pl-5"
                      >
                        <td
                          className=" pl-5 border-t py-4 p-2 text-left font-Poppins text-lightGray">
                          {user.teamMemberEmail}
                        </td>

                        <td 
                          onClick={() => handleTabs(user.ownersId, users)} 
                          className=" border-t py-4 text-cente px-2 hover:text-red-500 underline  text-blue-500 font-Poppins text-sm font-normal">
                          {user.companyEmail}
                        </td>
                        <td className=" border-t py-4 px-4 text-left text-lightGray font-Poppins text-sm font-normal">
                          {user.companyName}
                        </td>
                        <td className=" border-t py-4 text-left text-lightGray font-Poppins text-sm font-normal ">
                          <div className="flex flex-col px-2">
                            {convertDateTime(user.createdAt)}
                          </div>
                        </td>
                        <td className=" border-t py-4 text-center text-lightGray font-Poppins text-sm font-normal  px-2">
                            {user.user.isEmailVerified === true? (
                            <span
                              className="status"
                              style={makeStyle(user.user.isEmailVerified)}
                            >
                              Active
                            </span>) : (
                              <span
                              className="status"
                              style={makeStyle(user.user.isEmailVerified)}
                            >
                              Pending
                            </span>
                            )}
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Mobile view */}
              <div className="md:hidden">
                <div className="md:hidden max-h-auto p-3">
                  {currentPost.map((user) => (
                    <div
                      key={user.id}
                      className="flex gap-2 justify-between p-3 shadow-lg hover:shadow-2xl hover:bg-gray-200 rounded-md my-2 "
                    >
                      <div className="flex flex-col justify-start items-start align-middle flex-1 ">
                        <span className="text-black flex text-[12px]">
                          {convertDateTime(user.createdAt)}
                        </span>
                        <div className=" flex text-[12px] px-2">
                          {user.companyName}
                        </div>
                      </div>
                      <div className="flex flex-col justify-start items-start align-middle flex-1 ">
                        <span
                          className="text-black text-[12px] font-extrabold"
                          style={makeStyle(user.status)}
                        >
                          {user.user.status}
                        </span>
                        <span className="text-blue-800 underline text-[12px]">
                          {user.email}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
          {/* other errors */}
          {error && (
            <ErrorMessage message="An error occurred while fetching data" />
          )}
          {!error && users.length === 0 && !loading && (
            // when there are no records
            <Empty activeTab={activeTab} searchValue={searchValue} />
          )}
        </>
      ) : (
        <div className="h-[50vh] w-full mx-auto flex text-center font-extralight mt-3 flex-col">
          <h2>Sorry, No Records Found in {activeTab}</h2>
          <div className="h-[100%] w-full flex justify-center ">
            <Lottie loop={false} animationData={noRecords} />
          </div>
        </div>
      )}
      {data.total > 10 && (
        <PaginationComponent
          total={data.total}
          limit={10}
          onChange={setpageNumber}
          activePage={pageNumber}
        />
      )}
    </div>
      ) :
      tabs === "userDetails" ? (
        <div>
          <TeamTabRow 
              selectedUserId={selectedUser}
              users={users}
              actualUserId={selectedUser}/>
        </div>
      ): (
        <>
          <div>
          <TeamTabRow 
              selectedUserId={selectedUser}
              users={users}
              actualUserId={selectedUser} />
          </div>
        </>
      )}
    </div>
  );
};

export default Teams;
