import React, { useState } from "react";
import { format, parse, setDate } from "date-fns";
import moment from "moment";
import { enGB } from "date-fns/locale";
import DateRangeHook from "./useDateRangeHook";
// @ts-ignore
import "./ReactdatePicker.css";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Flex } from "@mantine/core";

interface Props {
  type: string;
  onClickDone: (arg: { startDate?: string; dayDate?: string; endDate?: string; }) => void;
  onClose: () => void;
  dates?: any,
  onChange: (arg: {
    startDate?: string  | null,
    dayDate?: string  | null,
    endDate?: string  | null,
  }) => void;

}

const DateRangePickerCalendarExample: React.FC<Props> = (props) => {
  const { type, onClose, onChange, dates } = props;

  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  });

  const handleSelect = (ranges: any) => {
    setSelectedDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection"
    });
  }

  const onClickDone = (setSelectedDateRange: any) => {
      const startSelectedDate = selectedDateRange.startDate.toString()
      const endSelectedDate = selectedDateRange.endDate.toString()

      if (startSelectedDate === endSelectedDate){
        const endDate = new Date(selectedDateRange.endDate);
        endDate.setDate(endDate.getDate() + 1);
        onChange({
          startDate: selectedDateRange.startDate.toString(),
          dayDate: selectedDateRange.startDate.toString(),
          endDate: endDate.toString(),
        })

      } else {
        onChange({
          startDate: selectedDateRange.startDate.toString(),
          dayDate: selectedDateRange.startDate.toString(),
          endDate: selectedDateRange.endDate.toString(),
        })
      }
      onClose()
  };

  const onClickClear = () => {
    onChange({
      startDate: null,
      dayDate: null,
      endDate: null,
    })
    onClose()
  };

  return (
    <div className="detail px-3 absolute left-[40%] top-[25%] ease-in-out duration-100" style={{borderRadius: 25, height:390,  width:450, backgroundColor: "white"}}>
      {
        props.type === "range" && (
          <Flex justify={"center"} direction="column" align={"center"}>
            <DateRange
              color="#FF0000"
              rangeColors={["#77C360"]}
              onChange={handleSelect}
              ranges={[selectedDateRange]}
              showDateDisplay={false}
              direction="horizontal"
            />
            <Flex justify={"center"} align={"center"} gap={"sm"} w={"100%"}>
              <button
                style={{color:"#77C360"}}
                className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
                onClick={onClickDone}
              >
                Done
              </button>
              <button
                className="btn btn-transparent text-danger rounded-0 px-4"
                onClick={onClickClear}
              >
                Clear
              </button>
            </Flex>
          </Flex>
        )
      }
    </div>
  );
}
//e
export default DateRangePickerCalendarExample;