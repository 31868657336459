import moveIcon from "../../assests/paperlink/icons/move-icon.svg";

const Dots = () => {
  return (
    <div>
      <img src={moveIcon} alt="" />
    </div>
  );
};
export default Dots;
